/**
 * @ngdoc Service
 * @name store
 *
 * @module tallyfy
 *
 * @description
 * Service for user storage
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .service('store', function (_, $rootScope, TFY_EVENTS, UsersService, $q, GuestTasksRepository, GroupsService) {
      var self = this,
        users = [], usersFullData = [], guests = [], groups = [], usersDeferred, usersFullDataDeferred, guestsDeferred, groupsDeferred;

      /**
       * public method's
       */
      self.getUsers = getUsers;
      self.setUsers = setUsers;
      self.getUsersFullData = getUsersFullData;
      self.setUsersFullData = setUsersFullData;
      self.getGuests = getGuests;
      self.setGuests = setGuests;
      self.getGroups = getGroups;
      self.setGroups = setGroups;
      self.removeUser = removeUser;
      self.clearUserStore = clearUserStore;
      self.clearGuestsStore = clearGuestsStore;
      self.clearGroupsStore = clearGroupsStore;
      self.getUserById = getUserById;
      self.updateUser = updateUser;
      self.getRandomGifInteger = getRandomGifInteger;

      /**
       * @ngdoc method
       * @name getUsers
       * @description Get all the orgnanization users(active/invited/disable)
       * @returns {object} users
       */
      function getUsers() {
        if (_.get(usersDeferred, 'state') === 'pending') {
          return usersDeferred.promise;
        }
        usersDeferred = $q.defer();
        if (usersFullData.length) {
          usersDeferred.resolve(usersFullData);
        } else if (users.length) {
          usersDeferred.resolve(users);
        } else {
          UsersService.getLighterUsersList({
            org: _.get($rootScope, 'identity.default_organization.id')
          }).then(function (response) {
            setUsers(response);
            usersDeferred.resolve(response.data);
          }, function (error) {
            usersDeferred.reject(error);
          });
        }
        return usersDeferred.promise;
      }

      /**
       * @ngdoc method
       * @name getUsersFullData
       * @description Get all the orgnanization users(active/invited/disable)
       * @returns {object} users
       */
      function getUsersFullData(params, paginationActive) {
        if (_.get(usersFullDataDeferred, 'state') === 'pending') {
          return usersFullDataDeferred.promise;
        }
        usersFullDataDeferred = $q.defer();
        if (usersFullData.length && !paginationActive) {
          usersFullDataDeferred.resolve(usersFullData);
        } else {
          UsersService.get(angular.extend(params || {},{
            org: _.get($rootScope, 'identity.default_organization.id')
          })).then(function (response) {
            if (!paginationActive) {
              setUsersFullData(response);
            }
            usersFullDataDeferred.resolve(response);
          }, function (error) {
            usersFullDataDeferred.reject(error);
          });
        }
        return usersFullDataDeferred.promise;
      }

      /**
       * @ngdoc method
       * @name getGuests
       * @description Get all the organization guests
       * @returns {object} guests
       */
      function getGuests() {
        if (_.get(guestsDeferred, 'state') === 'pending') {
          return guestsDeferred.promise;
        }
        guestsDeferred = $q.defer();
        if (guests.length) {
          guestsDeferred.resolve(guests);
        } else {
          GuestTasksRepository.get().then(function (response) {
            setGuests(response);
            guestsDeferred.resolve(response.data);
          }, function (error) {
            guestsDeferred.reject(error);
          });
        }
        return guestsDeferred.promise;
      }

      /**
       * @ngdoc method
       * @name getGroups
       * @description Get all the organization groups
       * @returns {object} groups
       */
      function getGroups() {
        if (_.get(groupsDeferred, 'state') === 'pending') {
          return groupsDeferred.promise;
        }
        groupsDeferred = $q.defer();
        if (groups.length) {
          groupsDeferred.resolve(groups);
        } else {
          GroupsService.getOrgGroups().then(function (response) {
            setGroups(response);
            groupsDeferred.resolve(response.data);
          }, function (error) {
            groupsDeferred.reject(error);
          });
        }
        return groupsDeferred.promise;
      }
      
      /**
       * @ngdoc method
       * @name getUserById
       * @param {*} userId
       * @description Get user by id
       * @returns {Object} user
       */
      function getUserById(userId) {
        return UsersService.get({
          org: _.get($rootScope, 'identity.default_organization.id'),
          id: userId,
          with: 'country,reassign_required,auth_methods,member_watchers.watcher'
        });
      }

      /**
       * @ngdoc method
       * @name setUsers
       * @description Set users
       * @param {object} res
       * @returns {void}
       */
      function setUsers(res) {
        clearUserStore();
        angular.extend(users, res.data);
      }

      /**
       * @ngdoc method
       * @name setUsersFullData
       * @description Set users
       * @param {object} res
       * @returns {void}
       */
      function setUsersFullData(res) {
        clearUserStore();
        angular.extend(usersFullData, res.data);
      }

      /**
       * @ngdoc method
       * @name setGuests
       * @description Set guests
       * @param {object} res
       * @returns {void}
       */
      function setGuests(res) {
        clearGuestsStore();
        angular.extend(guests, res.data);
      }

      /**
       * @ngdoc method
       * @name setGroups
       * @description Set groups
       * @param {object} res
       * @returns {void}
       */
      function setGroups(res) {
        clearGroupsStore();
        angular.extend(groups, res.data);
      }

      /**
       * @ngdoc method
       * @name updateUser
       * @description Update user from users array
       * @param user
       */
      function updateUser(user) {
        if (_.keys(user).length) {
          var index = getIndex(user.id);
          if (index >= 0) {
            angular.extend(users[index], user);
            return;
          }
          users.push(user);
        }
      }

      /**
       * @ngdoc method
       * @name removeUser
       * @description remove user from users array
       * @param user
       */
      function removeUser(user) {
        var index = getIndex(user.id);
        if (index >= 0) {
          users.splice(index, 1);
        }
      }

      /**
       * @ngdoc method
       * @name getIndex
       * @description get user index from users
       * @param id
       * @return index
       */
      function getIndex(id) {
        return _.findIndex(users, {
          id: id
        });
      }

      /**
       * @ngdoc method
       * @name clearUserStore
       * @description clear users array
       */
      function clearUserStore() {
        users.length = 0;
        usersFullData.length = 0;
      }

      /**
       * @ngdoc method
       * @name clearGuestsStore
       * @description clear guests array
       */
      function clearGuestsStore() {
        guests.length = 0;
      }

      /**
       * @ngdoc method
       * @name clearGroupsStore
       * @description clear groups array
       */
      function clearGroupsStore() {
        groups.length = 0;
      }

      /**
       * @ngdoc method
       * @name getRandomGifInteger
       * @description select random images(gif) number
       */
      function getRandomGifInteger() {
        return Math.floor((Math.random() * 11) + 1);
      }

      /**
       * event handler when users value has been updated
       * @param {*} event
       * @param {*} data
       * @type {*|(function())}
       */
      $rootScope.$on(TFY_EVENTS.USER.UPDATE_DETAILS, function (event, data) {
        updateUser(data);
      });
    });
})();
