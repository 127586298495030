/**
 * @ngdoc component
 * @name tallyfy.compactSubNav
 *
 * @author Shubham Prasanna ( gmail::shubhamprasanna@gmail.com, skype :: shubham.prasanna )
 **/
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('compactSubNav', {
      templateUrl: 'app/components/compact/components/subNav/compact-sub-nav.component.html',
      bindings: {
        process: '<',
        isActiveProcess: '<',
        havePermissionToEdit: '<?',
        havePermissionToRead: '<?',
        activeTab: '<',
        onViewsChange: '&',
        toggleAutomations: '&?',
        togglePowerBuilder: '&',
        onSettingsChange: '&',
        onMoreChange: '&',
        bpView: '<?',
        availableUsers: '<',
        runStatus: '<?',
        forceOnboarding: '<?',
        hasTasks: '<?',
        havePermissionToDuplicate: '<?',
        showHiddenTasks: '<?',
        processStatus: '<?',
        folderId: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, Helper, ProcessService, DateUtils, BLUEPRINT_TYPE, $filter, FolderService, Growl) {
          var $ctrl = this, growl = new Growl();

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.isViewsPopOverOpen = false;
          $ctrl.isSettingsPopOverOpen = false;
          $ctrl.isMorePopOverOpen = false;
          $ctrl.isFolderOpen = false;
          $ctrl.analyticsKey = 'TE > Sub Nav';

          $ctrl.getLastSavedTime = getLastSavedTime;
          $ctrl.toggleViewsChange = toggleViewsChange;
          $ctrl.toggleSettingsChange = toggleSettingsChange;
          $ctrl.toggleMoreChange = toggleMoreChange;
          $ctrl.onNewFolderForBlueprint = onNewFolderForBlueprint;
          $ctrl.onRemoveFromFolder = onRemoveFromFolder;

          function onInit() {
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            $ctrl.isLightMember = _.isEqual(_.get($rootScope, 'identity.role'), "light");
            $ctrl.haveAuthority = Helper.checkAccessAuthority(false);
            if (!Helper.isObjectEmpty($ctrl.process)) {
              initSubNav();
            }
            $ctrl.subNavElem = angular.element(document.querySelector('.compact-sub-nav'));
          }

          function onChanges(changes) {
            if (changes.process && changes.process.currentValue !== changes.process.previousValue && !Helper.isObjectEmpty(changes.process.currentValue)) {
              initSubNav();
            }
            if (changes.activeTab && changes.activeTab.currentValue !== changes.activeTab.previousValue) {
              var readKey = $ctrl.isActiveProcess ? 'view' : $ctrl.bpView.READ;
              $ctrl.activeTabObj = _.find($ctrl.viewsOptions, { id: changes.activeTab.currentValue === readKey ? 'read' : 'edit' });
            }
            if ((changes.showHiddenTasks && changes.showHiddenTasks.currentValue !== changes.showHiddenTasks.previousValue)
              || (changes.processStatus && changes.processStatus.currentValue !== changes.processStatus.previousValue)) {
              $ctrl.moreOptions = ProcessService.getMoreOptions($ctrl.isActiveProcess, _.get($ctrl.process, 'status') === 'archived', $ctrl.isLightMember, $ctrl.isAdminMember, $ctrl.havePermissionToDuplicate, $ctrl.havePermissionToRead, $ctrl.showHiddenTasks);
            }
            if (changes.hasTasks && changes.hasTasks.currentValue !== changes.hasTasks.previousValue) {
              $ctrl.isToolsDisabled = $ctrl.isActiveProcess ? ($ctrl.process.ko_form_fields.data.length > 0 && ($ctrl.process.progress.complete + 1) === $ctrl.process.progress.total) || $ctrl.process.status === 'complete' || ($ctrl.isProcessHavingDocumentType && !changes.hasTasks.currentValue) : false;
            }
          }

          function onDestroy() { }

          function initSubNav () {
            $ctrl.isProcessHavingFormType = _.get($ctrl.process, 'type') === BLUEPRINT_TYPE.FORM;
            $ctrl.isProcessHavingProcedureType = _.get($ctrl.process, 'type') === BLUEPRINT_TYPE.PROCEDURE;
            $ctrl.isProcessHavingDocumentType = _.get($ctrl.process, 'type') === BLUEPRINT_TYPE.DOCUMENT;
            if ($ctrl.isActiveProcess) {
              $ctrl.analyticsKey = 'AP > Sub Nav';
              if ($ctrl.process.started_by) {
                $ctrl.processStarter = _.find($ctrl.availableUsers, {
                  id: $ctrl.process.started_by
                });
              } else {
                $ctrl.processStarter = _.assign($rootScope.identity, {
                  text: $rootScope.identity.full_name
                });
              }
            }
            var owner = _.get($ctrl.process, 'users') && $ctrl.process.users.length ? $ctrl.process.users : $ctrl.process.owner_id,
            havePermissionTab = true;
            $ctrl.viewsOptions = ProcessService.getViewsOptions($ctrl.havePermissionToEdit, $ctrl.havePermissionToRead, true, $ctrl.isActiveProcess, $ctrl.isProcessHavingDocumentType);
            $ctrl.settingsOptions = ProcessService.getTabOptions(havePermissionTab, false, !$ctrl.isActiveProcess, $ctrl.havePermissionToEdit);
            $ctrl.moreOptions = ProcessService.getMoreOptions($ctrl.isActiveProcess, $ctrl.process.status === 'archived', $ctrl.isLightMember, $ctrl.isAdminMember, $ctrl.havePermissionToDuplicate, $ctrl.havePermissionToRead, $ctrl.showHiddenTasks);
            var readKey = $ctrl.isActiveProcess ? 'view' : $ctrl.bpView.READ;
            $ctrl.activeTabObj = _.find($ctrl.viewsOptions, { id: $ctrl.activeTab === readKey ? 'read' : 'edit' });
            $ctrl.showTools = $ctrl.isActiveProcess ? !(($ctrl.process.type == 'form' && $ctrl.process.progress.total == 1) || $ctrl.isGuest || $ctrl.process.status === 'archived') :
              $ctrl.havePermissionToEdit && !$ctrl.isProcessHavingDocumentType;
            $ctrl.isToolsDisabled = $ctrl.isActiveProcess ? ($ctrl.process.ko_form_fields.data.length > 0 && ($ctrl.process.progress.complete + 1) === $ctrl.process.progress.total) || $ctrl.process.status === 'complete' || ($ctrl.isProcessHavingDocumentType && !$ctrl.hasTasks) : false;
            $ctrl.showSettings = $ctrl.isActiveProcess ? ($ctrl.process.status && !($ctrl.forceOnboarding || ($ctrl.isGuest || ($rootScope.userState === 'PUBLIC_PROCESS')))) : true;
            $ctrl.showMore = $ctrl.isActiveProcess ? $ctrl.showSettings : ($ctrl.showSettings && $ctrl.havePermissionToEdit);
            $ctrl.permissionTabObj = _.find($ctrl.settingsOptions, { value: 'permissions' });
          }

          function getLastSavedTime() {
            return $filter('amTimeAgo')(DateUtils.toTimezone($ctrl.process.last_updated).value().subtract(3, 'seconds'));
          }

          function toggleViewsChange (option) {
            if (option.isDisabled) {
              return;
            }
            if ((option.id === $ctrl.activeTabObj.id) && $ctrl.activeTab !== _.get($ctrl.bpView, 'AUTOMATIONS')) {
              $ctrl.isViewsPopOverOpen = false;
              return;
            }
            if ($ctrl.isViewsPopOverOpen) {
              $ctrl.isViewsPopOverOpen = !$ctrl.isViewsPopOverOpen;
            }
            if (option.id === 'read') {
              $rootScope.$emit('AP_PAGE_VIEW:CHANGE');
            }
            if (option.id === 'activity') {
              var activityTab = _.find($ctrl.settingsOptions, { value: option.id });
              toggleSettingsChange(activityTab);
            } else {
              $ctrl.onViewsChange({ isEdit: (option.id === 'edit') });
            }
          }

          function toggleSettingsChange (option) {
            if ($ctrl.isSettingsPopOverOpen) {
              $ctrl.isSettingsPopOverOpen = !$ctrl.isSettingsPopOverOpen;
            }
            $ctrl.onSettingsChange({ tab: option });
          }

          function toggleMoreChange (option, $event) {
            if (option.id === 'folder') {
              $event.stopPropagation();
              $ctrl.isFolderOpen = !$ctrl.isFolderOpen;
            } else {
              if ($ctrl.isMorePopOverOpen) {
                $ctrl.isMorePopOverOpen = !$ctrl.isMorePopOverOpen;
                $ctrl.isFolderOpen = false;
              }
              $ctrl.onMoreChange({ tab: option });
            }
          }

          /**
           * @ngdoc method
           * @name onNewFolderForBlueprint
           * @param {*} process
           *
           * @description
           * on new folder for blueprint added / directly added blueprint into a folder after created
           */
          function onNewFolderForBlueprint(process) {
            if (!$ctrl.haveAuthority) {
              return;
            }
            var createModal = FolderService.openFolderModal(null, null, 'checklist');
            createModal.result.then(function (response) {
              var newFolder = response.data;
              process.folder_id = newFolder.id;
              ProcessService.update({
                id: process.id
              }, process).then(function (res) {
                newFolder.total = 0;
                var folderExist = _.find($ctrl.folderItemsToDisplay, { type: 'folder' });
                if ((folderExist || $ctrl.pager.current_page === 1) && !$ctrl.folderId) {
                  $ctrl.folderItemsToDisplay.splice(0, 0, {
                    id: newFolder.id,
                    type: 'folder',
                    data: newFolder
                  });
                }
                var folderIndex = _.findIndex($ctrl.folders, { id: newFolder.id });
                if (folderIndex >= 0) {
                  $ctrl.folderId ? $ctrl.folders[folderIndex].total-- : $ctrl.folders[folderIndex].total++;
                }
                growl.success($filter('translate')('runs.messages.folder.addedToFolder', {
                  bpName: res.data.title
                }), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
              });
            });
          }

          // Remove from folder & move to uncategorized
          function onRemoveFromFolder(process) {
            $rootScope.$emit('FOLDER:REMOVE', { blueprint: process });
          }
        }
    });
})();
