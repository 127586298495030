/**
 * @ngdoc Component
 * @name tallyfy.tasks.component.editTask
 * @module tallyfy.tasks
 *
 * @description
 * task component
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.tasks')
    .component('editTask', {
      templateUrl: 'app/modules/tasks/editTask/editTaskNew.html',
      bindings: {
        dismiss: '&',
        close: '&',
        resolve: '<'
      },
      require: {},
      controller:
        /*@ngInject*/
        function (_, $rootScope, blockUI, UsersService, $scope, TasksService, Helper, moment, RunsService, Growl, Auth, $filter, CONST, COMMON, 
          $analytics, DateUtils, DATEFORMAT, store, DESCRIPTIONSIZE, $state, $timeout, AuthPlan, $uibModal, PLANS, MODAL_CLOSE_EVENTS, 
          StepService, $q, TagsService, TAG, OrganizationsService, BLUEPRINT_TYPE) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('editTaskForm'),
            oldTask,
            currentUser = $rootScope.identity,
            growl = new Growl(),
            onModalClosePreventHandler,
            createOrUpdateTimeoutHandler;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public properties
           */
          $ctrl.startData = DateUtils.toTimezone().value().add(2, 'h').format();
          $ctrl.users = [];
          $ctrl.guests = [];
          $ctrl.groups = [];
          $ctrl.userLists = [];
          $ctrl.editTask = false;
          $ctrl.isRunTask = false;
          $ctrl.invalidDeadline = true;
          $ctrl.oneOffTaskList = [];
          $ctrl.activeTaskIndex = void 0;
          $ctrl.savingTask = false;
          $ctrl.dateFormat = OrganizationsService.getDateFormat();

          /**
           * public methods
           */
          $ctrl.cancel = cancel;
          $ctrl.loadTags = loadTags;
          $ctrl.taskOwnersAsStr = taskOwnersAsStr;
          $ctrl.isTomorrow = isTomorrow;
          $ctrl.onUserSelect = onUserSelect;
          $ctrl.onUserAdded = onUserAdded;
          $ctrl.onUserRemoved = onUserRemoved;
          $ctrl.onRemovingUser = onRemovingUser;
          $ctrl.saveTask = saveTask;
          $ctrl.discardChanges = discardChanges;
          $ctrl.deleteTask = deleteTask;
          $ctrl.getTagClass = getTagClass;
          $ctrl.toggleExpanded = toggleExpanded;
          $ctrl.openInviteModal = UsersService.openInviteModal;
          $ctrl.openUpgradeModal = openUpgradeModal;
          $ctrl.onAdvanceClick = onAdvanceClick;
          $ctrl.onTaskTypeSelected = onTaskTypeSelected;
          $ctrl.taskTypes = StepService.getStepTypes();
          $ctrl.toggleIsAssigneeLimited = toggleIsAssigneeLimited;
          $ctrl.onClickOpen = onClickOpen;
          $ctrl.onLinkToSelected = onLinkToSelected;
          $ctrl.tags = [];
          $ctrl.URL_REGEX = CONST.URL_REGEX;
          $ctrl.linkToTypes = TasksService.getLinkToTypes();
          $ctrl.discardSelected = discardSelected;
          $ctrl.onLinkToValueSelected = onLinkToValueSelected;
          $ctrl.getTagBackgroundColor = getTagBackgroundColor;
          $ctrl.getTagTextColor = TagsService.isTagTextColor;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.memberPrefixTranslation = Helper.getMemberPrefixTranslation();
            $ctrl.defaultDeadline = _.get($rootScope.identity, 'default_organization.default_deadline');
            var deadlineValue = _.get($ctrl.defaultDeadline, 'value', 5),
                deadlineType = _.get($ctrl.defaultDeadline, 'type', "days");
            $ctrl.deadline = DateUtils.toTimezone().value().add(deadlineValue, deadlineType).format();
            $ctrl.invalidSelection = moment($ctrl.deadline).diff(moment($ctrl.startData), 'seconds') <= 0;
            $ctrl.startData = $ctrl.invalidSelection ? DateUtils.toTimezone().value().format() : $ctrl.startData;
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            $ctrl.allow_user_invite = _.get($rootScope, 'orgSamlInfo.allow_user_invite');
            $ctrl.isFirstTime = true;
            $ctrl.isFocus = true;
            $ctrl.maxTitle = DESCRIPTIONSIZE.taskStepTitle;
            $ctrl.task = $ctrl.resolve.task ? angular.copy($ctrl.resolve.task) : TasksService.getStandaloneTaskModel();
            if ($ctrl.resolve.runId) {
              $ctrl.task.run_id = $ctrl.resolve.runId;
            }
            $ctrl.isAssignToGuest = $ctrl.resolve.isGuest;
            if ($ctrl.resolve.task) {
              if ($ctrl.resolve.task.run_id) {
                $ctrl.isRunTask = true;
              }
              $ctrl.editTask = true;
              $ctrl.deadline = moment($ctrl.task.deadline);
              $ctrl.deadline_unformatted = angular.copy($ctrl.task.deadline_unformatted);

              oldTask = angular.copy($ctrl.task);
              $ctrl.users = angular.copy($ctrl.resolve.taskOwner);
              if (!_.isUndefined($ctrl.task.owners.guests)) {
                $ctrl.guests = getTaskGuests($ctrl.task);
              }
              $ctrl.userLists = _.unionWith($ctrl.users, $ctrl.guests);
            } else {
              $ctrl.task.name = '';
            }
            if ($ctrl.resolve.koField) {
              var field = _.pick($ctrl.resolve.koField, ['field_type', 'guidance', 'label', 'name', 'position', 'required', 'title', 'columns', 'options'])
              angular.extend($ctrl.task, { 
                name: $ctrl.resolve.koField.label, 
                summary: '<ko-field-assignee data-ko-field-alias="\'' + $ctrl.resolve.koField.alias + '\'"></ko-field-assignee>', 
                form_fields: { data: [field] } 
              });
            }
            setUpFroalaEditors();

            if (!$ctrl.resolve.isOwner && !$ctrl.resolve.task && !$ctrl.resolve.isRequestBp) {
              assignDefaultAssignee();
            }
            getUsers();
            getGroups();
            getGuests();

            $ctrl.isDocsPlan = AuthPlan.isRestrictedWithDocsPlan();
            $ctrl.addUserPlaceholder = $filter('translate')('global.standAlone.hintTitleTextOnly' + $ctrl.memberPrefixTranslation, { isDocsPlan: $ctrl.isDocsPlan }, 'messageformat');
            $ctrl.isProPlan = [PLANS.PRO, PLANS.PROMONTHLY, PLANS.PROANNUAL].indexOf(AuthPlan.getCurrentPlanCode()) > -1;
            if ($ctrl.resolve.isRequestBp) {
              $timeout(function () {
                angular.element('#addDetails').trigger('click');
                angular.element('#addAssignee').trigger('click');
                $ctrl.task.name = $filter('translate')('global.standAlone.requestBpDefaultTitle');
                $ctrl.task.summary = $filter('translate')('global.standAlone.requestBpDefaultDescription');
              }, 105);
            }

            if ($ctrl.resolve.isQuickAction && $ctrl.resolve.quickActionTaskType) {
              setSelectedTaskType($ctrl.resolve.quickActionTaskType);
            } else {
              setSelectedTaskType($ctrl.task.task_type);
            }

            if ($ctrl.resolve.customTaskTranslations && $ctrl.resolve.customTaskTranslations.default_name) {
              $ctrl.task.name = $filter('translate')($ctrl.resolve.customTaskTranslations.default_name);
            }
            $ctrl.isAssigneeLimited = _.get($ctrl.task, 'max_assignable', 0) > 0;
            if ($ctrl.resolve.convertCommentToTask) {
              $ctrl.task.name = "Task from comment -";
              $ctrl.task.summary = "Comment from <a href='"+window.location.href+"' target='_blank' rel='noopener noreferrer'>this process</a> <hr> "+$ctrl.resolve.convertCommentToTask.content;
            }
            $ctrl.iAmAssigned = _.indexOf(_.get($ctrl.task, 'owners.users'), $rootScope.identity.id || $ctrl.viewerId) !== -1;
            $ctrl.isCurrentMemberInGroup = isCurrentMemberInGroupCheck($ctrl.task.owners.groups);
            $ctrl.isClaimTask = (($ctrl.task.owners.users.length > 1) || $ctrl.task.owners.groups.length) && (!$ctrl.task.everyone_must_complete) && ($ctrl.iAmAssigned || $ctrl.isCurrentMemberInGroup);
            $ctrl.fromEmail = OrganizationsService.getEmailPreferences();
          }

          function onTaskTypeSelected(taskType) {
            setSelectedTaskType(taskType.value);
            if (taskType.value === 'approval') {
              $ctrl.task.everyone_must_complete = false;
            }
          }

          function setSelectedTaskType(value) {
            if (!value) {
              value = 'task';
            }
            $ctrl.selectedTaskType = _.find($ctrl.taskTypes, { value: value });
            $ctrl.task.task_type = value;
          }

          /**
           * @ngdoc method
           * @name setUpFroalaEditors
           * @description 
           * Initialize the froala editors options when description box expanded
           * @returns Object
           */
          function setUpFroalaEditors() {
            var toolbarsButtons = [];
            $ctrl.froalaOptions = Helper.getFroalaOptions({
              placeholder: $filter('translate')('global.standAlone.placeholder.description'),
              enableFullscreen: true,
              currentUser: currentUser
            }, false, {
              moreText: {
                buttons: _.concat(
                  toolbarsButtons, [
                  "bold",
                  "italic",
                  "underline",
                  "align",
                  "formatOL",
                  "formatUL",
                  "fontSize",
                  "paragraphFormat",
                  "quote",
                  "fullscreen",
                  "textColor",
                  "insertImage",
                  "codeTag",
                  "emoticons",
                  "insertFile",
                  "insertVideo",
                  "insertLink",
                  "insertTable",
                  "embedly",
                  "pageBreak"
                ]),
                align: 'left',
                buttonsVisible: 10 + toolbarsButtons.length
              },
              colorsStep: 5,
              colorsText: ['#15E67F', '#E3DE8C', '#D8A076', '#D83762', '#76B6D8', 'REMOVE', '#1C7A90', '#249CB8', '#4ABED9', '#FBD75B', '#FBE571', '#FFFFFF']
            });
            $ctrl.froalaOptions.resizeable = true;
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() {

          }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            onModalClosePreventHandler();
            createOrUpdateTimeoutHandler ? $timeout.cancel(createOrUpdateTimeoutHandler) : angular.noop();
          }

          /**
           * @function
           * @name cancel
           * @description
           * Dismiss modal
           */
          function cancel() {
            $ctrl.dismiss();
          }

          /**
           * @function
           * @name getUsers
           * @description Get all users at once from user store
           */
          function getUsers() {
            blockUI.start();
            store.getUsers().then(function (response) {
              var activeUsers = UsersService.getBilledUsers(response);
              $ctrl.availableUsers = _.sortBy(activeUsers, 'text');
              assignSpecificAssignee($ctrl.availableUsers);
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @function
           * @name getGroups
           * @description Get all groups
           */
          function getGroups() {
            blockUI.start();
            store.getGroups().then(function (response) {
              $ctrl.orgGroups = response || [];
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @function
           * @name assignSpecificAssignee
           * @param {Object} users
           * @description Assign to specific user in assignee
           */
          function assignSpecificAssignee(users) {
            if ($ctrl.resolve.isOwner) {
              var user = _.find(users, { 'id': $ctrl.resolve.isOwner });
              if (_.isUndefined(user)) {
                assignDefaultAssignee();
                return;
              }
              $ctrl.users.push(user);
              $ctrl.userLists.push(user);
            }
          }

          /**
           * loadTags
           * filters tags based on search text
           * @param  {string} $query A search string
           * @return {array}        An array of filtered tags
           */
          function loadTags($query) {
            return _.filter(_.concat($ctrl.availableUsers, $ctrl.orgGroups, $ctrl.orgGuests), function (tag) {
              return tag.text.toLowerCase().indexOf($query.toLowerCase()) !== -1;
            });
          }

          /**
           * @ngdoc method
           * @name taskOwnersAsStr
           * @returns {String} owners
           */
          function taskOwnersAsStr() {
            if ($ctrl.userLists.length > 1) {
              return makeShort($ctrl.userLists[0].text, 'ASSIGNEE') + ' and ' + ($ctrl.userLists.length - 1) + ' others';
            }
            return makeShort($ctrl.userLists[0].text, 'ASSIGNEE');
          }
          /**
           * @ngdoc method
           * @name makeShort
           * @description Make 
           * @param {String} name
           * @param {String} type
           */
          function makeShort(name, type) {
            return name.length > COMMON.LIMITS[type] ? name.substring(0, COMMON.LIMITS[type]) + '...' : name;
          }

          /**
           * @function
           * @name createTask
           * @description Create task
           * @param {Object} task 
           */
          function createTask(task) {
            $ctrl.savingTask = true;
            task.started_at = DateUtils.toUTC(moment($ctrl.startData).format()).format(DATEFORMAT.DEFAULT);
            task.deadline = DateUtils.toUTC(moment($ctrl.deadline).format()).format(DATEFORMAT.DEFAULT);
            $ctrl.invalidSelection = moment($ctrl.deadline).diff($ctrl.startData, 'seconds') <= 0;
            var add2HoursStartDate = moment($ctrl.deadline).subtract(2, 'h');
            task.started_at = $ctrl.invalidSelection ? DateUtils.toUTC(moment(add2HoursStartDate).format()).format(DATEFORMAT.DEFAULT) : task.started_at;
            var isFormsFieldIncluded;
            beforeTaskFormCreate(task);
            Growl.clearAllMessages('global');
            formatUsers(task);
            if (_.get(task, 'form_fields.data', []).length) {
              isFormsFieldIncluded = true;
              task.form_fields = task.form_fields.data;
            } else {
              delete task.form_fields;
            }
            if ($ctrl.tags.length) {
              $ctrl.task.tags = _.map($ctrl.tags, function (tag) {
                return tag.tag_id ? tag.tag_id : tag.id;
              });
            }
            blockUI.start();
            TasksService.createStandaloneTask(task, isFormsFieldIncluded).then(function (response) {
              if (task.checklist_id || task.run_id) {
                var resources = [];
                if (_.get(task, 'run_id')) {
                  resources.push(RunsService.get({
                    id: task.run_id,
                    with: 'checklist'
                  }));
                }
                if (_.get(task, 'checklist_id')) {
                  resources.push(TasksService.createStandaloneTaskLinkByBlueprint({
                    id: _.get(response.data, 'id'),
                    checklist_id: task.checklist_id,
                    step_id: task.step_id
                  }));
                }
                $q.all(resources).then(function(res) {
                  if (_.get(task, 'run_id')) {
                    angular.extend(response.data, { run: { data : res[0].data } });
                  }
                  blockUI.stop();
                  $ctrl.savingTask = false;
                  updateStandaloneTask(response, task.separate_task_for_each_assignee);
                }, function () {
                   blockUI.stop();
                   $ctrl.savingTask = false;
                });
              } else {
                blockUI.stop();
                $ctrl.savingTask = false;
                updateStandaloneTask(response, task.separate_task_for_each_assignee);
              }
              Helper.isNewGuestAddedProcessAndReturn(_.get(response, 'data.owners.guests', [])).then(function (res) {
                if (res.isNewGuestAdded) {
                  $ctrl.orgGuests = res.updatedGuests;
                }
              });
            }, function () {
              blockUI.stop();
              $ctrl.savingTask = false;
            });
          }

          function updateStandaloneTask(response, separateTaskForEachAssignee) {
            showTaskCreationAlert(response.data);
            if (response.data) {
              if (separateTaskForEachAssignee && response.data.run_id) {
                $ctrl.close();
                $state.go('run.view', {
                  id: response.data.run_id
                });
              } else {
                $ctrl.oneOffTaskList.push(response.data);
                $ctrl.task = TasksService.getStandaloneTaskModel();
                if ($ctrl.resolve.runId) {
                  $ctrl.task.run_id = $ctrl.resolve.runId;
                  if (!$ctrl.resolve.isQuickAction) {
                    $rootScope.$emit('STANDALONE_TASK:CREATED', { task: response.data, taskOpen: true, unmapped: !$ctrl.task.stage_id });
                  }
                }
                if ($ctrl.resolve.isQuickAction) {
                  viewNewlyCreatedTask({ "task": response.data });
                } else {
                  $ctrl.isFocus = true;
                  $ctrl.isFirstTime = false;
                  $ctrl.editTaskForm.$setPristine();
                  $ctrl.webhookUrlEnabled = false;
                  $ctrl.isAssigneeLimited = false;
                  setSelectedTaskType($ctrl.task.task_type);
                  $ctrl.linkToType = null;
                }
              }
            }
          }

          /**
           * @ngdoc method
           * @name showTaskCreationAlert
           * @description Show Growl for task creation
           * @param {*} task
           */
          function showTaskCreationAlert(task) {
            var alertObj = {
              referenceId: 'oneOffTask',
              disableIcons: true,
              disableCloseButton: true
            };
            if (task.owners.guests.length) {
              angular.extend(alertObj, {
                variables: {
                  method: copyGuestLink,
                  param: task,
                  linkText: $filter('translate')('tasks.messages.copyGuestLink')
                }
              });
            } else {
              if (_.get(task, 'run.data.type', '') !== BLUEPRINT_TYPE.DOCUMENT) {
                angular.extend(alertObj, {
                  variables: {
                    method: viewNewlyCreatedTask,
                    param: {
                      "task": task
                    },
                    linkText: $filter('translate')('global.task.messages.viewTask')
                  }
                });
              }
            }
            growl.success($filter('translate')('global.task.messages.taskCreated'), alertObj);
          }

          /**
           * @ngdoc method
           * @name viewNewlyCreatedTask
           * @description Link to newly created tasks' list view
           * @param {*} params
           */
          function viewNewlyCreatedTask(params) {
            if (_.keys(params).length > 0) {
              var userId = _.head(_.concat(_.get(params, 'task.owners.users', []), _.get(params, 'task.owners.guests', [])));
              if ($ctrl.resolve.runId) {
                $ctrl.close();
                $rootScope.$emit('RIGHT_PANE:SELECTED_TASK_OPEN', {
                  item: {
                    task: $ctrl.oneOffTaskList[$ctrl.oneOffTaskList.length - 1],
                    index: parseInt($ctrl.activeTaskIndex)
                  },
                  rightPaneCustomClass: 'compact-container-task-detail'
                });
              } else {
                navigateToHome({
                  assignee: userId, activeTask: _.get(params, 'task.id', ''), newOneOffTaskList: $ctrl.oneOffTaskList
                });
              }
            }
          }

          /**
           * @ngdoc method
           * @name  navigateToHome
           * @description An helper method that redirects to home page with default params
           * A newly created task is visible on {status: 'to-do', sortBy: 'newest', assignee: OWNER_ID} 
           * @param {*} params An object that containes  {assignee, activeTask}
           * @returns void
           */
          function navigateToHome(params) {
            params = _.keys(params).length > 0 ? _.merge({}, params, { status: 'to-do', sortBy: 'newest', isRequireToOpenOneOffTaskModal: false }) : { org_id: currentUser.default_organization.id, status: 'to-do', sortBy: 'newest', isRequireToOpenOneOffTaskModal: false };
            if ($ctrl.resolve.isQuickAction) {
              $ctrl.close();
            }
            $state.go('home', params);
          }

          /**
           * @function
           * @name updateTask
           * @description Update task
           * @param {object} task
           * @returns {object}
           */
          function updateTask(task) {
            var oldOwner = $ctrl.task.owners.users;
            formatUsers(task);
            task.deadline = DateUtils.toUTC(moment($ctrl.deadline).format()).format(DATEFORMAT.DEFAULT);
            blockUI.start();
            $ctrl.savingTask = true;
            if ($ctrl.isRunTask) {
              TasksService.updateTask({ id: task.id, action_id: $state.params.id }, task).then(function (response) {
                getUpdatedRun(response, oldOwner);
                trackEvent('Task edited', { type: 'process ', action: 'edit' });
                $ctrl.savingTask = false;
              }, function () {
                blockUI.stop();
                $ctrl.savingTask = false;
              });
            } else {
              TasksService.updateStandaloneTask({ id: task.id }, task).then(function (response) {
                $ctrl.close({
                  $value: angular.copy([response.data])
                });
                trackEvent('Task edited', { type: 'standalone ', action: 'edit' });
                blockUI.stop();
                $ctrl.savingTask = false;
                selfRemovalCheck(response.data, oldOwner);
              }, function () {
                blockUI.stop();
                $ctrl.savingTask = false;
              });
            }
          }

          /*
           * @function
           * @name selfRemovalCheck
           * @description To show message while doing self removal from task.
           * @param {object} task
           */
          function selfRemovalCheck(task, oldOwner) {
            Auth.getIdentity().then(function (identity) {
              if (!_.isEqual(oldOwner, task.owners.users) && task.owners.users.indexOf(identity.id) === -1) {
                growl.success($filter('translate')('global.task.messages.reassignSuccess', { taskName: makeShort(task.step.title, 'TASKNAME'), users: $ctrl.taskOwnersAsStr() }), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              } else {
                var growlParams = {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                };
                var isGuestRemain = _.isEqual(oldTask.owners.guests.sort(), task.owners.guests.sort());
                if (task.owners.guests.length && !isGuestRemain) {
                  angular.extend(growlParams, {
                    variables: {
                      method: copyGuestLink,
                      param: task,
                      linkText: $filter('translate')('tasks.messages.copyGuestLink')
                    }
                  });
                }
                growl.success($filter('translate')('tasks.messages.taskUpdated'), growlParams);
              }
            });
          }

          /**
           * @ngdoc method
           * @param {*} task
           * 
           * @description
           * copy guest link to clipboard 
           */
          function copyGuestLink(task) {
            if (!task) {
              return;
            }
            Growl.clearAllMessages('global');
            $uibModal.open({
              component: 'copyGuestUrl',
              windowClass: 'copy-guest-url-modal',
              backdrop: 'static',
              resolve: {
                owners: function () {
                  return task.owners;
                }
              }
            });
          }

          /*
           * @function
           * @name getUpdatedRun
           * @description To get the updated run data for task
           * @param {object} updatedTaskResponse
           * @returns {object}
           */
          function getUpdatedRun(updatedTaskResponse, oldOwner) {
            RunsService.get({
              id: updatedTaskResponse.data.run_id,
              with: 'tasks'
            }).then(function (updatedRunResponse) {
              $ctrl.close({
                $value: angular.copy([updatedTaskResponse.data, _.omit(updatedRunResponse.data, ['tasks'])])
              });

              blockUI.stop();
              selfRemovalCheck(updatedTaskResponse.data, oldOwner);
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @function
           * @name formatUsers
           * @description Format user to send as users and guests
           * @param {Object} task 
           */
          function formatUsers(task) {
            task.owners.users = [];
            task.owners.guests = [];
            task.owners.groups = [];
            _.forEach($ctrl.users, function (value, key) {
              _.has(value, 'id') ? task.owners['users'].push(value.id) : task.owners['users'].push(value);
            });

            if (!_.isEmpty($ctrl.guests)) {
              task.owners.guests = $ctrl.guests;
            }

            if (!_.isEmpty($ctrl.groups)) {
              task.owners.groups = $ctrl.groups;
            }
          }

          /**
           * @function
           * @name isTomorrow
           * @public
           * @description Check the selected time is tomorrow or not
           */
          function isTomorrow() {
            var newDate = moment().toDate();
            if (!_.isNull($ctrl.deadline) && !$ctrl.deadline._isAMomentObject) {
              if ($ctrl.deadline.getDate && ($ctrl.deadline.getDate() - newDate.getDate()) === 1) {
                return true;
              }
            }
            return false;
          }

          /**
           * @function
           * @name onUserSelect
           * @description On select user in the tags input
           * @param {Object} user 
           */
          function onUserSelect(user) {
            if (!user.id) {
              if (!Helper.isValidEmail(user.text)) {
                return false;
              } else if ($ctrl.groups.length && user.type !== 'group' && isUserExist($ctrl.availableUsers, user)) {
                angular.extend(user, _.find($ctrl.availableUsers, function (userObj) {
                  return userObj.email === user.text;
                }));
                return !Helper.isMemberPresentInGroup(user, angular.copy($ctrl.groups), $ctrl.orgGroups, true, true);
              }
              return true;
            } else if ($ctrl.groups.length && user.type !== 'group' && Helper.isMemberPresentInGroup(user, angular.copy($ctrl.groups), $ctrl.orgGroups, false, true)) {
              return false;
            } else if (user.type === 'guest' && Helper.isGuestPresentInGroup(user, angular.copy($ctrl.groups), $ctrl.orgGroups, false, true)) {
              return false;
            }
            return true;
          }

          /**
           * @function
           * @name isUserExist
           * @description Check a user already exist
           * @param {Object} users 
           * @param {Object} user 
           */
          function isUserExist(users, user) {
            return _.some(users, function (owner) {
              return owner.email === user.text || user.email;
            });
          }

          /**
           * @function
           * @name onUserAdded
           * @description User add after adding in the tags input
           * @param value
           */
          function onUserAdded(value) {
            if (!isUserExist($ctrl.availableUsers, value) || value.type === 'guest') {
              if (!value.id || value.type === 'guest') {
                value.id = value.text;
                $ctrl.guests.push(value);
              } else {
                $ctrl.groups.push(value.id);
              }
            } else {
              if (!value.id) {
                angular.extend(value, _.find($ctrl.availableUsers, function (user) {
                  return user.email === value.text;
                }));
              }
              $ctrl.users.push(value);
            }
          }

          /**
           * @function
           * @name onUserRemoved
           * @description User removed after removing in the tags input
           * @param $owner 
           */
          function onUserRemoved($owner) {
            if (!isUserExist($ctrl.availableUsers, $owner) || $owner.type === 'guest') {
              if ($owner.type === 'group') {
                var groupIndex = _.indexOf($ctrl.groups, $owner.id);
                if (groupIndex > -1) {
                  $ctrl.groups.splice(groupIndex, 1);
                }
              } else {
                $ctrl.guests.splice($ctrl.guests.indexOf($owner), 1);
              }
            } else {
              var index = _.findIndex($ctrl.users, { 'id': $owner.id });
              $ctrl.users.splice(index, 1);
            }
          }

          /**
           * @function
           * @name onRemovingUser
           * @description check before removing user. if only one coworker then it won't delete the user
           * @param $owner 
           */
          function onRemovingUser($owner) {
            if ((!isUserExist($ctrl.availableUsers, $owner) && $owner.type !== 'group') || ($owner.type === 'guest')) {
              return true;
            } else {
              return !(($ctrl.users.length + $ctrl.groups.length) === 1);
            }
          }

          /**
           * @function
           * @name saveTask
           * @description Save task on submit form
           * @param {Object} task 
           */
          function saveTask(task) {
            if($ctrl.task.run_id) {
              angular.extend(task, {
                position: !_.isUndefined($ctrl.activeTaskIndex) ? $ctrl.activeTaskIndex : task.position
              });
            }
            if ($ctrl.editTaskForm.$valid) {
              if ($ctrl.savingTask) {
                return;
              }
              if (!$ctrl.webhookUrlEnabled) {
                $ctrl.task.webhook = void 0;
              }
              createOrUpdateTimeoutHandler ? $timeout.cancel(createOrUpdateTimeoutHandler) : angular.noop();
              createOrUpdateTimeoutHandler = $timeout(function () {
                $ctrl.editTask ? updateTask(task) : createTask(task);
              }, 700);
            }
          }

          /**
           * @function
           * @name discardChanges
           * @description Discard changes from the standalone form
           */
          function discardChanges() {
            $ctrl.task = angular.copy(oldTask);
            $ctrl.dismiss({ $value: $ctrl.task });
          }

          /**
           * @function
           * @name deleteTask
           * @description Delete task
           * @param {Object} task
           */
          function deleteTask(task) {
            blockUI.start();
            TasksService.deleteStandaloneTask({ id: task.id }).then(function () {
              cancel();
              growl.success($filter('translate')('tasks.general.deleteTask'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true,
                variables: {
                  method: restoreStandaloneTask,
                  param: {
                    "id": task.id
                  },
                  linkText: $filter('translate')('tasks.general.undo')
                }
              });
              blockUI.stop();
              $rootScope.$emit('STANDALONE_TASK:DELETE', { 'id': task.id });
            }, function () {
              cancel();
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name restoreStandaloneTask
           * @param {*} task 
           * @return void
           * 
           * @description
           * to restore standalone task
           */
          function restoreStandaloneTask(task) {
            Growl.clearAllMessages('global');
            blockUI.start();
            TasksService.restoreStandaloneTask(task).then(function (response) {
              growl.success($filter('translate')('tasks.general.restoreTask'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              blockUI.stop();
              $rootScope.$emit('STANDALONE_TASK:RESTORE', response.data);
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @function
           * @name assignDefaultAssignee
           * @description Assign default users to assignee field
           */
          function assignDefaultAssignee() {
            $ctrl.currentUser = $rootScope.identity;
            var user = {
              id: $ctrl.currentUser.id,
              text: $ctrl.currentUser.full_name,
              email: $ctrl.currentUser.email,
              profile_pic: $ctrl.currentUser.profile_pic,
              first_name: $ctrl.currentUser.first_name,
              last_name: $ctrl.currentUser.last_name
            };
            $ctrl.userLists.push(user);
            $ctrl.users.push(user);
          }

          /**
           * @ngdoc method
           * @name getTaskGuests
           * @description Return all guest with format
           * @param {object} task
           * @returns {array} A list of users
           */
          function getTaskGuests(task) {
            return _.map(task.owners.guests, function (guest) {
              return { id: guest, 'text': guest };
            });
          }

          /**
           * @function
           * @name getTagClass
           * @description Callback function tagClass in ngTagsInput
           * @param {String} user 
           */
          function getTagClass(user) {
            if (Helper.isValidEmail(user.text)) {
              return 'tag-email';
            }
          }

          /**
           * @function
           * @name toggleExpanded
           * @description toggle input
           * @param {event} e
           * @param {*} field
           */
          function toggleExpanded(e, field) {
            e.stopImmediatePropagation();
            $timeout(function () {
              var el = angular.element(e.target);
              el.attr('aria-expanded') === 'false' ? el.trigger('click') : angular.noop();
              var collapse = angular.element(document.getElementById(el.attr('aria-controls')));
              el.attr('aria-controls') === 'Collapse1' ? $ctrl.froalaOptions.froalaEditor.$el.focus() : collapse.find('input').get(0).focus();
            }, 100);
          }

          /**
           * @name trackEvent
           * @param {*} eventName 
           * @param  {*} properties
           * 
           * @description
           * method to handling analytics event
           */
          function trackEvent(eventName, properties) {
            properties.type = !$ctrl.task.run_id ? 'standalone' : 'process';
            properties.processId = $ctrl.task.run_id;
            properties.taskId = $ctrl.task.id;
            $analytics.eventTrack(eventName, properties);
          }

          function openUpgradeModal() {
            if (!AuthPlan.hasAnyAuthority(PLANS.RESTRICATED_PLANS, 'standaloneAdvancedSettings')) {
              return;
            }
          }

          /**
           * @ngdoc method
           * @name onAdvanceClick
           * 
           * @description
           * on open advanced settings clicked handler
           */
          function onAdvanceClick() {
            $ctrl.advanceCollapse = !$ctrl.advanceCollapse;
            $ctrl.advanceCollapse ? $('.create-task-modal').addClass('advance-open') : $('.create-task-modal').removeClass('advance-open');
          }

          function beforeTaskFormCreate(task) {
            _.forEach(_.get(task.form_fields, 'data', []), function (field) {
              if (field.isNew) {
                delete field.isNew;
                delete field.id;
              }
              field.name = field.label;
              delete field.slug;
              if (field.options) {
                field.options = prepareOptions(field.options);
              }
            });
          }

          /**
           * @function
           * @name prepareOptions
           * @description Prepare field options
           * @param {Object} options
           * @returns {Object}
           */
          function prepareOptions(options) {
            return _.remove(options, function (option) {
              return (option.text !== null && option.text !== "");
            });
          }

          function toggleIsAssigneeLimited() {
            $ctrl.task.max_assignable = $ctrl.isAssigneeLimited ? 1 : 0;
          }

          function getGuests() {
            blockUI.start();
            store.getGuests().then(function (res) {
              $ctrl.orgGuests = res;
              _.forEach($ctrl.orgGuests, function(guestItem) {
                guestItem.id = guestItem.email;
                guestItem.text = guestItem.email;
                guestItem.type = 'guest';
              });
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name onClickOpen
           * @param {String} type
           * @description
           * Open popup model to edit task data
           */
          function onClickOpen(type) {
            switch (type) {
              case 'assign':
                $ctrl.assignedPopoverIsOpen = true;
                break;
              case 'deadline':
                $ctrl.deadlinePopoverIsOpen = true;
                break;
              case 'summary':
                $ctrl.summaryPopoverIsOpen = true;
                break;
              case 'started_at':
                $ctrl.isStartedAtOpen = true;
                break;
            }
          }

          /**
           * @ngdoc method
           * @name isCurrentMemberInGroupCheck
           * @description
           * check for member in groups
           */
          function isCurrentMemberInGroupCheck(groups) {
            var isMemberPresent = false, groupsArr = groups || [];
            for (var i = 0; i < groupsArr.length; i++) {
              var groupObj = _.find($ctrl.orgGroups, { id: groupsArr[i] });
              if (groupObj) {
                for (var j = 0; j < groupObj.members.length; j++) {
                  if (groupObj.members[j] === $rootScope.identity.id && ($ctrl.task.owners.users.length >= 1 || groupObj.members.length > 1 || groupsArr.length > 1)) {
                    isMemberPresent = true;
                    break;
                  }
                }
              }
              if (isMemberPresent) {
                break;
              }
            }
            return isMemberPresent;
          }

          //Handle task link to dropdown click
          function onLinkToSelected (type) {
            $ctrl.linkToType = type.id;

          }

          //Discard selected blueprint/process
          function discardSelected () {
            $ctrl.linkToType = null;
            $ctrl.selectedProcess = null;
          }

          //Handle task link value selected
          function onLinkToValueSelected (value, type) {
            type !== 'position' ? $ctrl.selectedProcess = value : $ctrl.activeTaskIndex = value;
          }

          //Method to get tag background color
          function getTagBackgroundColor (tag) {
            if (!tag.color) {
              return TAG.TAGCOLOR;
            }
            return tag.color;
          }

          /**
           * Modal close prevent handler
           */
          onModalClosePreventHandler = $scope.$on('modal.closing', function (event, reason) {
            if ($ctrl.editTaskForm.$dirty && (reason === MODAL_CLOSE_EVENTS.OUTSIDECLICK || reason === MODAL_CLOSE_EVENTS.ESCAPEPRESS)) {
              event.preventDefault();
            }
          });
        }
    });
})();
