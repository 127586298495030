/**
 * @ngdoc Component
 * @name tallyfy.process.component.templateEditPermission
 * @module tallyfy.process
 *
 * @description
 *  A component to edit the details of a template permissions
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.process')
    .component('templateEditPermission', {
      bindings: {
        users: '<',
        guests: '<',
        process: '<',
        bluePrintOwner: '<',
        form: "=",
        permissionType: '<',
        processStarter: '<',
        processOwner: '<',
        orgGroups: '<?',
        isPublic: '=?',
        isShowPermission: '=?'
      },
      templateUrl: 'app/modules/process/components/edit/info/permissions/template-edit-permissions.html',
      controller:
        /*@ngInject*/
        function (_, Helper, $filter, ProcessService, blockUI, Growl, RunsService, $rootScope, TFY_EVENTS, AuthPlan, $timeout, $scope) {
          var $ctrl = this,
            process,
            growl = new Growl(),
            blockUI = blockUI.instances.get('templateEdit'),
            isPermissionSaved = false,
            unregisterUpdatedTaskCollaboratorsListener,
            defaultUsers = { id: "all", text: $filter('translate')('global.roles.allAdminMembers')},
            oldUsersAndGroups = {
              edit: [],
              read: [],
              launch: [],
              process: [],
              duplicate: []
            };

          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public method
           */
          $ctrl.loadOwner = loadOwner;
          $ctrl.getSaveText = getSaveText;
          $ctrl.savePermissions = savePermissions;
          $ctrl.discardPermissions = discardPermissions;
          $ctrl.isAnyPermissionExists = isAnyPermissionExists;
          $ctrl.toggleAccessOwners = toggleAccessOwners;
          $ctrl.onUserSelect = onUserSelect;
          $ctrl.onUserOrGuestSelect = onUserOrGuestSelect;
          $ctrl.onRemovingUser = onRemovingUser;
          $ctrl.onChangeShowPermission = onChangeShowPermission;

          /**
           * public properties
           */
          $ctrl.editAssignee = [];
          $ctrl.readAssignee = [];
          $ctrl.duplicateAssignee = [];
          $ctrl.launchAssignee = [];
          $ctrl.processAssignee = [];
          $ctrl.isEditAccess = false;
          $ctrl.accesses = {
            edit: false,
            read: false,
            launch: false,
            process: false,
            duplicate: false
          };
          $ctrl.permissionAssignee = [];
          $ctrl.getTooltipTitle = '';

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.helpTooltip = $filter('translate')('permissions.helpIcon');
            process = angular.copy($ctrl.process);
            if ($ctrl.permissionType === 'BluePrint') {
              $ctrl.nonAdminUsers = getNonAdminUsers($ctrl.process.created_by, $ctrl.process.owner_id);
              setBPPermissionsState();
            }
            if ($ctrl.permissionType === 'ActiveProcess' || $ctrl.permissionType === 'EditProcess') {
              $ctrl.nonAdminUsers = getNonAdminUsers($ctrl.processStarter, $ctrl.processOwner);
              getActiveProcessPermissions();
            }
            $ctrl.isDocsPlan = AuthPlan.isRestrictedWithDocsPlan();
            permissionAssigneeWatcherHandler();
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            unregisterUpdatedTaskCollaboratorsListener();
          }

          /**
           * @ngdoc method
           * @name getNonAdminUsers
           * @description get non admin members
           * @param {Integer} starter
           * @param {Integer} owner
           */
          function getNonAdminUsers(starter, owner) {
            return _.filter($ctrl.users, function (item) {
              return item.role !== "admin" && !_.includes([starter, owner], item.id);
            });
          }

          /**
           * @ngdoc method
           * @name setPermissionsGeneric
           * @description Set permissions to objects - generic
           */
          function setPermissionsGeneric(propertyStrAPI, propertyStrClient, clientObjectStr) {
            oldUsersAndGroups[propertyStrClient] = getAssignedUsersData(_.get($ctrl.process, 'can_edit_perms.' + propertyStrAPI, []), _.get($ctrl.process, 'can_edit_perms.' + propertyStrAPI + '_group', []));
            $ctrl.accesses[propertyStrClient] = _.get($ctrl.process, 'can_edit_perms.' + propertyStrAPI, []).length > 0 || _.get($ctrl.process, 'can_edit_perms.' + propertyStrAPI + '_group', []).length > 0;
            $ctrl[clientObjectStr] = angular.copy(oldUsersAndGroups[propertyStrClient]);
          }

          /**
           * @ngdoc method
           * @name setBPPermissionsState
           * @description Set BP permissions' state
           */
          function setBPPermissionsState() {
            setPermissionsGeneric('checklist_edit', 'edit', 'editAssignee');
            setPermissionsGenericWithGuests('checklist_read', 'read', 'readAssignee');
            setPermissionsGeneric('checklist_duplicate', 'duplicate', 'duplicateAssignee');
            setPermissionsGeneric('process_launch', 'launch', 'launchAssignee');
            setPermissionsGeneric('process_read', 'process', 'processAssignee');
          }

          /**
           * @ngdoc method
           * @name setPermissionsGenericWithGuests
           * @description Set permissions to objects - generic
           */
          function setPermissionsGenericWithGuests(propertyStrAPI, propertyStrClient, clientObjectStr) {
            oldUsersAndGroups[propertyStrClient] = getAssignedUsersData(_.get($ctrl.process, 'can_edit_perms.' + propertyStrAPI, []), _.get($ctrl.process, 'can_edit_perms.' + propertyStrAPI + '_group', []), _.get($ctrl.process, 'can_edit_perms.' + propertyStrAPI + '_guest', []));
            $ctrl.accesses[propertyStrClient] = _.get($ctrl.process, 'can_edit_perms.' + propertyStrAPI, []).length > 0 || _.get($ctrl.process, 'can_edit_perms.' + propertyStrAPI + '_group', []).length > 0 || _.get($ctrl.process, 'can_edit_perms.' + propertyStrAPI + '_guest', []).length > 0;
            $ctrl[clientObjectStr] = angular.copy(oldUsersAndGroups[propertyStrClient]);
          }

          /**
           * @ngdoc method
           * @name getActiveProcessPermissions();
           * @description get users who have prcess access permissions
           */
          function getActiveProcessPermissions() {
            var nonAdminMembers = angular.copy($ctrl.nonAdminUsers);
            var standardUsers = _.filter(_.map(nonAdminMembers, 'id'), function(userId) {
              return _.includes($ctrl.process.collaborators, userId);
            });
            var owner = _.get($ctrl.process, 'users') && $ctrl.process.users.length < 0 ? $ctrl.process.owner_id : $ctrl.process.users,
             ownerStarterCreator = _.filter(_.map(nonAdminMembers, 'id'), function(userId) {
              return _.includes(owner,  userId) || _.isEqual($ctrl.process.started_by,  userId);
            });
            var allPermittedUsers = _.concat(standardUsers, ownerStarterCreator, _.get($ctrl.process, 'can_edit_perms.process_read'));
            $ctrl.accesses.process = (_.get($ctrl.process, 'can_edit_perms.process_read', []).length > 0) || (_.get($ctrl.process, 'can_edit_perms.process_read_group', []).length > 0);
            oldUsersAndGroups.process = getAssignedUsersData(allPermittedUsers, _.get($ctrl.process, 'can_edit_perms.process_read_group'));
            $ctrl.processAssignee = angular.copy(oldUsersAndGroups.process);
          }

          /**
           * @ngdoc method
           * @name savePermissions
           * @description Save the permissions
           */
          function savePermissions() {
            if ($ctrl.form.processPermissionForm.$invalid) {
              return;
            }
            process = angular.copy($ctrl.process);
            if ($ctrl.permissionType === 'BluePrint') {
              prepareBPPermissionsBeforeUpdate();
              saveProcess(process);
            }
            if ($ctrl.permissionType === 'ActiveProcess') {
              preparePermissionsBeforeUpdateGeneric('process', 'processAssignee', 'process_read');
              saveRun(process);
            }
            if($ctrl.permissionType === 'EditProcess') {
              preparePermissionsBeforeUpdateGeneric('process', 'processAssignee', 'process_read');
              angular.extend($ctrl.process.can_edit_perms, process.can_edit_perms);
              isPermissionSaved = true;
              $ctrl.form.processPermissionForm.$setPristine();
              $timeout(function() {
                growl.success($filter('translate')('process.messages.runUpdate'), {
                  referenceId: 'blueprintPermissionAlert',
                  disableIcons: true,
                  disableCloseButton: true
                });
              }, 200);
            }
          }

          function onChangeShowPermission(value) {
            $ctrl.isShowPermission = value;
            if ($ctrl.isShowPermission) {
              $ctrl.isPublic = !$ctrl.isShowPermission;
            }
          }

          /**
           * @ngdoc method
           * @name preparePermissionsBeforeUpdateGeneric
           * @description prepare permissions - generic
           * and set params before save the process
           */
          function preparePermissionsBeforeUpdateGeneric(propertyStrClient, clientObjectStr, propertyStrAPI) {
            if ($ctrl.accesses[propertyStrClient] && _.get($ctrl, clientObjectStr + '.length', []) > 0) {
              process.can_edit_perms[propertyStrAPI] = _.map(_.filter($ctrl[clientObjectStr] || [], function(user) {
                return user.id !== 'all' && user.type === 'member';
              }), 'id');
              process.can_edit_perms[propertyStrAPI + '_group'] = _.map(_.filter($ctrl[clientObjectStr] || [], function(user) {
                return user.id !== 'all' && user.type === 'group';
              }), 'id');
              process.can_edit_perms[propertyStrAPI + '_guest'] = _.map(_.filter($ctrl[clientObjectStr] || [], function(user) {
                return user.id !== 'all' && user.type === 'guest';
              }), 'email');
              var addGuest = _.map(_.filter($ctrl[clientObjectStr] || [], function(user) {
                return user.id !== 'all' && !_.includes(['guest', 'member', 'group'], user.type) && Helper.isValidEmail(user.text);
              }), 'text');
              process.can_edit_perms[propertyStrAPI + '_guest'] = _.concat(process.can_edit_perms[propertyStrAPI + '_guest'], addGuest);
            } else {
              $ctrl[clientObjectStr] = [defaultUsers];
              _.set(process,'can_edit_perms.' + propertyStrAPI, []);
              _.set(process,'can_edit_perms.' + propertyStrAPI + '_group', []);
              _.set(process,'can_edit_perms.' + propertyStrAPI + '_guest', []);
            }
          }

          /**
           * @ngdoc method
           * @name prepareBPPermissionsBeforeUpdate
           * @description Prepare BP permissions before update
           */
          function prepareBPPermissionsBeforeUpdate() {
            preparePermissionsBeforeUpdateGeneric('edit', 'editAssignee', 'checklist_edit');
            preparePermissionsBeforeUpdateGeneric('read', 'readAssignee', 'checklist_read');
            preparePermissionsBeforeUpdateGeneric('duplicate', 'duplicateAssignee', 'checklist_duplicate');
            preparePermissionsBeforeUpdateGeneric('launch', 'launchAssignee', 'process_launch');
            preparePermissionsBeforeUpdateGeneric('process', 'processAssignee', 'process_read');
          }

          /**
           * @ngdoc method
           * @name setEmptyPermissionsGeneric
           * @description set empty permissions - generic
           * and set params before save the process
           */
          function setEmptyPermissionsGeneric(propertyStrClient, propertyStrAPI, currentUser) {
            if ($ctrl.accesses[propertyStrClient] && (_.get(process, 'can_edit_perms.' + propertyStrAPI, []).length < 1) && (_.get(process, 'can_edit_perms.' + propertyStrAPI + '_group', []).length < 1) && (_.get(process, 'can_edit_perms.' + propertyStrAPI + '_guest', []).length < 1)) {
              process.can_edit_perms[propertyStrAPI].push(currentUser);
            }
          }

          /**
           * @ngdoc method
           * @name saveProcess
           * @description Save the template/blueprint
           * @param {Object} process
           */
          function saveProcess(process) {
            var currentUser = _.get($rootScope.identity, 'id');
            setEmptyPermissionsGeneric('edit', 'checklist_edit', currentUser);
            setEmptyPermissionsGeneric('read', 'checklist_read', currentUser);
            setEmptyPermissionsGeneric('duplicate', 'checklist_duplicate', currentUser);
            setEmptyPermissionsGeneric('launch', 'process_launch', currentUser);
            setEmptyPermissionsGeneric('process', 'process_read', currentUser);
            if ($ctrl.orgGroups.length > 0 && _.get(process, 'can_edit_perms.checklist_read_group').length > 0) {
              process.can_edit_perms.checklist_read_guest_group = [];
              _.forEach(process.can_edit_perms.checklist_read_group, function (group) {
                var findGroup = _.find($ctrl.orgGroups, function(g) {
                  return g.id === group;
                });
                if (_.get(findGroup, 'guests.length', 0) > 0) {
                  process.can_edit_perms.checklist_read_guest_group.push(findGroup.id);
                }
              });
            }
            $ctrl.onSaving = true;
            blockUI.start();
            ProcessService.setPermission({
              id: process.id
            }, process.can_edit_perms).then(function (response) {
              $ctrl.onSaving = false;
              _.set($ctrl.process, 'can_edit_perms', response.data);
              blockUI.stop();
              growl.success($filter('translate')('process.messages.processUpdate'), {
                referenceId: 'blueprintPermissionAlert',
                disableIcons: true,
                disableCloseButton: true
              });
              setBPPermissionsState();
              updatePermissions();
            }, function () {
              $ctrl.onSaving = false;
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name saveRun
           * @description Save the run permissions
           * @param {*} process
           */
          function saveRun(process) {
            $ctrl.onSaving = true;
            blockUI.start();
            if (_.get(process, 'can_edit_perms.process_read').length < 1 && _.get($ctrl.accesses, 'process') === true) {
              process.can_edit_perms.process_read.push(_.get($rootScope.identity, 'id'));
            }
            RunsService.setRunPermission({
              id: process.id
            },process.can_edit_perms).then(function (response) {
              blockUI.stop();
              $ctrl.onSaving = false;
              growl.success($filter('translate')('process.messages.runUpdate'), {
                referenceId: 'blueprintPermissionAlert',
                disableIcons: true,
                disableCloseButton: true
              });
              angular.extend($ctrl.process.can_edit_perms, response.data);
              getActiveProcessPermissions();
              updatePermissions();
            }, function () {
              $ctrl.onSaving = false;
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name updatePermissions
           * @description update the all permission
           * after save/update the template/blueprint
           */
          function updatePermissions() {
            isPermissionSaved = true;
            $ctrl.form.processPermissionForm.$setPristine();
            if ($ctrl.permissionType === 'BluePrint') {
              oldUsersAndGroups.edit = getAssignedUsersData(_.get($ctrl.process, 'can_edit_perms.checklist_edit', []), _.get($ctrl.process, 'can_edit_perms.checklist_edit_group', []));
              oldUsersAndGroups.read = getAssignedUsersData(_.get($ctrl.process, 'can_edit_perms.checklist_read', []), _.get($ctrl.process, 'can_edit_perms.checklist_read_group', []), _.get($ctrl.process, 'can_edit_perms.checklist_read_guest', []));
              oldUsersAndGroups.launch = getAssignedUsersData(_.get($ctrl.process, 'can_edit_perms.process_launch', []), _.get($ctrl.process, 'can_edit_perms.process_launch_group', []));
            }
            oldUsersAndGroups.process = getAssignedUsersData(_.get($ctrl.process, 'can_edit_perms.process_read', []), _.get($ctrl.process, 'can_edit_perms.process_read_group', []));
          }

          /**
           * @ngdoc method
           * @name discardPermissions
           * @description discard the permissions or
           * set original value 
           */
          function discardPermissions() {
            isPermissionSaved = false;
            if ($ctrl.permissionType === 'BluePrint') {
              $ctrl.accesses = {
                edit: (_.get($ctrl.process, 'can_edit_perms.checklist_edit', []).length > 0) || (_.get($ctrl.process, 'can_edit_perms.checklist_edit_group', []).length > 0),
                read: (_.get($ctrl.process, 'can_edit_perms.checklist_read', []).length > 0) || (_.get($ctrl.process, 'can_edit_perms.checklist_read_group', []).length > 0),
                launch: (_.get($ctrl.process, 'can_edit_perms.process_launch', []).length > 0) || (_.get($ctrl.process, 'can_edit_perms.process_launch_group', []).length > 0),
                process: (_.get($ctrl.process, 'can_edit_perms.process_read', []).length > 0) || (_.get($ctrl.process, 'can_edit_perms.process_read_group', []).length > 0)
              };
              $ctrl.editAssignee = angular.copy(oldUsersAndGroups.edit);
              $ctrl.readAssignee = angular.copy(oldUsersAndGroups.read);
              $ctrl.duplicateAssignee = angular.copy(oldUsersAndGroups.duplicate);
              $ctrl.launchAssignee = angular.copy(oldUsersAndGroups.launch);
              $ctrl.processAssignee = angular.copy(oldUsersAndGroups.process);
              $ctrl.editAssigneeOverText = '';
              $ctrl.duplicateAssigneeOverText = '';
              $ctrl.readAssigneeOverText = '';
              $ctrl.launchAssigneeOverText = '';
              $ctrl.processAssigneeOverText = '';
            }
            if ($ctrl.permissionType === 'ActiveProcess') {
              $ctrl.accesses.process = (_.get($ctrl.process, 'can_edit_perms.process_read', []).length > 0) || (_.get($ctrl.process, 'can_edit_perms.process_read_group', []).length > 0);
              $ctrl.processAssignee = angular.copy(oldUsersAndGroups.process);
              $ctrl.processAssigneeOverText = '';
            }
            $ctrl.form.processPermissionForm.$setPristine();
            $ctrl.form.processPermissionForm.$setUntouched();
          }
          
          /**
           * @ngdoc method
           * @name getAssignedUsersData
           * @description get assigned user general 
           * information by id
           * @returns {Array}
           * @param {Array} users
           * @param {Array} groups
           * @param {Array} guest
           */
          function getAssignedUsersData(users, groups, guest) {
            var assignedGroups, assignedUsers, assignedGuests = [];
            assignedUsers = _.filter(angular.copy($ctrl.users), function (item) {
              return _.indexOf(users, item.id) >= 0;
            }); 
            assignedGroups = _.filter($ctrl.orgGroups || [], function (item) {
              return _.indexOf(groups, item.id) >= 0;
            });
            if(guest) {
              assignedGuests = _.filter($ctrl.guests || [], function (item) {
                return _.indexOf(guest, item.email) >= 0;
              });
            }
            return _.concat(assignedUsers, _.concat(assignedGroups || [], assignedGuests || []));
          }

          /**
           * @ngdoc method
           * @name loadOwner
           * @description load users
           * @param {*} $query
           * @param {Boolean} allowGuest
           * @param {*} permissionType
           * @returns {Array}
           */
          function loadOwner($query, allowGuest, permissionType) {
            var users = _.concat($ctrl.users, $ctrl.orgGroups || []);
            if (allowGuest) {
              users = _.concat(users, $ctrl.guests);
            }
            return _.filter(users, function (owner) {
              var text = owner.text || owner.email || owner.name || '', userRole = _.get(owner, 'role', '');
              if ((permissionType === 'edit' || permissionType === 'duplicate') && userRole === 'light') {
                return false;
              }
              return text.toLowerCase().includes($query.toLowerCase());
            });
          }

          /**
           * @ngdoc method
           * @name getSaveText
           * @description return text based on condition
           * @returns button text
           */
          function getSaveText() {
            if ($ctrl.form.processPermissionForm.$dirty || !isPermissionSaved) {
              return $filter('translate')('steps.buttons.save_step');
            }
            return $filter('translate')('steps.buttons.saved');
          }

          /**
           * @ngdoc method
           * @name getSaveText
           * @description return text based on condition
           * @returns button text
           */
          function isAnyPermissionExists() {
           return _.includes(angular.copy($ctrl.accesses),true);
          }

          /**
           * @ngdoc method
           * @name toggleAccessOwners
           * @description Toggle step owner
           * @param {*} permitType
           * @param {*} permitValue
           */
          function toggleAccessOwners(permitType, permitValue) {
            switch(permitType) {
              case 'edit':
                $ctrl.editAssignee = [];
                break;
              case 'read':
                $ctrl.readAssignee = [];
                break;
              case 'duplicate':
                $ctrl.duplicateAssignee = [];
                break;
              case 'launch':
                $ctrl.launchAssignee = [];
                break;
              case 'process':
                $ctrl.processAssignee = [];
                break;
              default:
                break;
            }
            permissionAssigneeWatcherHandler();
            if ($ctrl.accesses[permitType] !== permitValue) {
              $ctrl.accesses[permitType] = permitValue;
              $ctrl.form.processPermissionForm.$setDirty(true);
            }
          }

          /**
           * @function
           * @name onUserSelect
           * @description On select user in the tags input
           * @param {Object} user
           * @param {*} permissionType
           */
          function onUserSelect(user, permissionType) {
            permissionAssigneeWatcherHandler();
            return isUserExist(user, permissionType);
          }

          /**
           * @function
           * @name onUserOrGuestSelect
           * @description On select user and guest in the tags input
           * @param {Object} user
           */
          function onUserOrGuestSelect(user) {
            permissionAssigneeWatcherHandler();
            $ctrl.readGroups = _.filter(oldUsersAndGroups['read'], function (group) {
              return group.type === "group";
            });
            if (user.type === 'member') {
              if(Helper.isMemberPresentInGroup(user, angular.copy($ctrl.readGroups), $ctrl.orgGroups, false, true)) {
                return false;
              }
              return _.some(_.concat($ctrl.users, $ctrl.guests), function (owner) {
                return owner.email === user.text || user.email;
              });
            } else if (user.type === 'group') {
              oldUsersAndGroups['read'].push(user);
              return _.some($ctrl.orgGroups || [], function (group) {
                return group.id === user.id;
              });
            } else if (!!user.text) {
              if (user.type === 'guest' && Helper.isGuestPresentInGroup(user, $ctrl.readGroups, $ctrl.orgGroups, false, true)) {
                return false;
              }
              var findGuest = _.some($ctrl.guests, function (guest) {
                return guest.email === user.text || user.email;
              });
              if (!findGuest && Helper.isValidEmail(user.text)) {
                 $ctrl.guests = _.concat($ctrl.guests, { email: user.text, text: user.text, type: 'guest' });
                 return true;
              } else {
               return findGuest;
              }
            } else {
              return false;
            }
          }

          /**
           * @function
           * @name onRemovingUser
           * @description On remove user in the tags input
           * @param {Object} user
           */
          function onRemovingUser(user) {
            permissionAssigneeWatcherHandler();
            return user.id !== 'all';
          }

          /**
           * @function
           * @name isUserExist
           * @description Check a user already exist
           * @param {Object} user
           * @param {*} permissionType
           */
          function isUserExist(user, permissionType) {
            $ctrl.permissionGroups = _.filter(oldUsersAndGroups[permissionType], function (group) {
              return group.type === "group";
            });
            if (user.type === 'member') {
              if (Helper.isMemberPresentInGroup(user, angular.copy($ctrl.permissionGroups), $ctrl.orgGroups, false, true)) {
                return false;
              }
              return _.some(_.concat($ctrl.users, $ctrl.guests), function (owner) {
                return owner.email === user.text || user.email;
              });
            } else if (user.type === 'group') {
              oldUsersAndGroups[permissionType].push(user);
              return _.some($ctrl.orgGroups || [], function (group) {
                return group.id === user.id;
              });
            } else {
              return false;
            }
          }

          function permissionAssigneeWatcherHandler () {
            $timeout(function() {
              var edit = _.filter(_.uniqBy($ctrl.editAssignee, 'id'), function(d){ return d.role !== 'admin' && d.id !== 'all'; }),
               read = _.filter(_.uniqBy($ctrl.readAssignee, 'id'), function(d){ return d.role !== 'admin' && d.id !== 'all'; }),
               duplicate = _.filter(_.uniqBy($ctrl.duplicateAssignee, 'id'), function(d){ return d.role !== 'admin' && d.id !== 'all'; }),
               launch = _.filter(_.uniqBy($ctrl.launchAssignee, 'id'), function(d){ return d.role !== 'admin' && d.id !== 'all'; }),
               process = _.filter(_.uniqBy($ctrl.processAssignee, 'id'), function(d){ return d.role !== 'admin' && d.id !== 'all'; }),
               temp = { edit: edit.length>0, read: read.length>0, duplicate: duplicate.length>0, launch: launch.length>0, process: process.length>0 },
               adminOnlyRead = _.filter(_.uniqBy($ctrl.readAssignee, 'id'), function(d){ return d.role === 'admin' || d.id !== 'all'; }),
               adminOnlyEdit = _.filter(_.uniqBy($ctrl.editAssignee, 'id'), function(d){ return d.role === 'admin' || d.id !== 'all'; }),
               adminOnlyDuplicate = _.filter(_.uniqBy($ctrl.duplicateAssignee, 'id'), function(d){ return d.role === 'admin' || d.id !== 'all'; }),
               adminOnlyLaunch = _.filter(_.uniqBy($ctrl.launchAssignee, 'id'), function(d){ return d.role === 'admin' || d.id !== 'all'; }),
               adminOnlyProcess = _.filter(_.uniqBy($ctrl.processAssignee, 'id'), function(d){ return d.role === 'admin' || d.id !== 'all'; }),
               editSummaryWithAdmin = _.join(_.map(_.filter(_.uniqBy($ctrl.editAssignee, 'id'), function(d){ return d.id !== 'all'; }), 'text'), ', '),
               readSummaryWithAdmin = _.join(_.map(_.filter(_.uniqBy($ctrl.readAssignee, 'id'), function(d){ return d.id !== 'all'; }), 'text'), ', '),
               duplicateSummaryWithAdmin = _.join(_.map(_.filter(_.uniqBy($ctrl.duplicateAssignee, 'id'), function(d){ return d.id !== 'all'; }), 'text'), ', '),
               launchSummaryWithAdmin = _.join(_.map(_.filter(_.uniqBy($ctrl.launchAssignee, 'id'), function(d){ return d.id !== 'all'; }), 'text'), ', '),
               processSummaryWithAdmin = _.join(_.map(_.filter(_.uniqBy($ctrl.processAssignee, 'id'), function(d){ return d.id !== 'all'; }), 'text'), ', '),
               editSummary = _.join(_.map(edit, 'text'), ', '),
               readSummary = _.join(_.map(read, 'text'), ', '),
               duplicateSummary = _.join(_.map(duplicate, 'text'), ', '),
               launchSummary = _.join(_.map(launch, 'text'), ', '),
               processSummary = _.join(_.map(process, 'text'), ', ');
               angular.extend(temp, {
                 editSummary: editSummary,
                 readSummary: readSummary,
                 duplicateSummary: duplicateSummary,
                 launchSummary: launchSummary,
                 processSummary: processSummary,
                 edit: $ctrl.editAssignee,
                 read: $ctrl.readAssignee,
                 duplicate: $ctrl.duplicateAssignee,
                 launch: $ctrl.launchAssignee,
                 process: $ctrl.processAssignee,
                 adminOnly: {
                    read: readSummary === '' && adminOnlyRead.length > 0,
                    edit: editSummary === '' && adminOnlyEdit.length > 0,
                    duplicate: duplicateSummary === '' && adminOnlyDuplicate.length > 0,
                    read_edit: editSummary === '' && readSummary === '' && adminOnlyRead.length > 0 && adminOnlyEdit.length > 0,
                    read_duplicate: readSummary === '' && duplicateSummary === '' && adminOnlyRead.length > 0 && adminOnlyDuplicate.length > 0,
                    edit_duplicate: editSummary === '' && duplicateSummary === '' && adminOnlyEdit.length > 0 && adminOnlyDuplicate.length > 0,
                    read_edit_duplicate: editSummary === '' && readSummary === '' && duplicateSummary === '' && adminOnlyRead.length > 0 && adminOnlyEdit.length > 0 && adminOnlyDuplicate.length > 0,
                    launch: launchSummary === '' && adminOnlyLaunch.length > 0,
                    process: processSummary === '' && adminOnlyProcess.length > 0,
                    launch_process: launchSummary === '' && processSummary === '' && adminOnlyLaunch.length > 0 && adminOnlyProcess.length > 0
                 },
                 equalAssignStandard: {
                   read_launch: isDiffrentAssignee(read, launch),
                   read_process: isDiffrentAssignee(read, process),
                   edit_launch: isDiffrentAssignee(edit, launch),
                   edit_process: isDiffrentAssignee(edit, process),
                   duplicate_launch: isDiffrentAssignee(duplicate, launch),
                   duplicate_process: isDiffrentAssignee(duplicate, process),
                   launch_process: isDiffrentAssignee(launch, process),
                   read_edit: isDiffrentAssignee(read, edit),
                   read_duplicate: isDiffrentAssignee(read, duplicate),
                   edit_duplicate: isDiffrentAssignee(edit, duplicate),
                   read_edit_duplicate: isDiffrentAssignee(read, edit) && isDiffrentAssignee(edit, duplicate),
                   read_duplicate_process: isDiffrentAssignee(read, duplicate) && isDiffrentAssignee(duplicate, process),
                   read_duplicate_launch: isDiffrentAssignee(read, duplicate) && isDiffrentAssignee(duplicate, launch),
                   read_edit_launch: isDiffrentAssignee(read, edit) && isDiffrentAssignee(edit, launch),
                   read_edit_process: isDiffrentAssignee(read, edit) && isDiffrentAssignee(edit, process),
                   edit_duplicate_process: isDiffrentAssignee(edit, duplicate) && isDiffrentAssignee(duplicate, process),
                   edit_duplicate_launch: isDiffrentAssignee(edit, duplicate) && isDiffrentAssignee(duplicate, launch),
                   read_edit_duplicate_process: isDiffrentAssignee(read, edit) && isDiffrentAssignee(edit, duplicate) && isDiffrentAssignee(duplicate, process),
                   read_edit_duplicate_launch: isDiffrentAssignee(read, edit) && isDiffrentAssignee(edit, duplicate) && isDiffrentAssignee(duplicate, launch),
                   read_edit_launch_process: isDiffrentAssignee(read, edit) && isDiffrentAssignee(edit, launch) && isDiffrentAssignee(launch, process),
                   read_duplicate_launch_process: isDiffrentAssignee(read, duplicate) && isDiffrentAssignee(duplicate, launch) && isDiffrentAssignee(launch, process),
                   edit_duplicate_launch_process: isDiffrentAssignee(edit, duplicate) && isDiffrentAssignee(edit, launch) && isDiffrentAssignee(launch, process),
                   read_edit_duplicate_launch_process: isDiffrentAssignee(read, edit) && isDiffrentAssignee(edit, duplicate) && isDiffrentAssignee(edit, launch) && isDiffrentAssignee(launch, process)
                 },
                 translateSummary: restPermissionText(readSummaryWithAdmin, editSummaryWithAdmin, duplicateSummaryWithAdmin, launchSummaryWithAdmin, processSummaryWithAdmin)
              });
              
              $ctrl.adminOnly = isCheckObjectValueTrue(temp.adminOnly);
              $ctrl.equalAssignStandard = isCheckObjectValueTrue(temp.equalAssignStandard);
              var data = _.split(_.last($ctrl.equalAssignStandard), "_");
              $ctrl.isAlreadyPrint = {
                edit: _.includes(data, 'edit'), read: _.includes(data, 'read'), duplicate: _.includes(data, 'duplicate'), launch: _.includes(data, 'launch'), process: _.includes(data, 'process')
              };
              angular.extend(temp, { isAdminOnly: $ctrl.adminOnly, isEqualAssignStandard: $ctrl.equalAssignStandard });
              $ctrl.permissionAssignee = angular.copy(temp);
            }, 1000);
          }

          function isDiffrentAssignee(data1 , data2) {
            return (data1.length > 0 && data2.length > 0)? data1.length === data2.length && data1.length === data2.length && _.difference(_.map(data1, 'id'), _.map(data2, 'id')).length === 0 : false;
          }

          function restPermissionText(read, edit, duplicate, launch, process) {
            return (read && edit && duplicate && launch && process)? 'read_edit_duplicate_launch_process'
              : (read && edit && launch && process)? 'read_edit_launch_process'
              : (read && edit && duplicate && launch)? 'read_edit_duplicate_launch'
              : (read && edit && duplicate && process)? 'read_edit_duplicate_process'
              : (edit && duplicate && launch && process)? 'edit_duplicate_launch_process'
              : (read && duplicate && launch && process)? 'read_duplicate_launch_process'
              : (edit && duplicate && launch && process)? 'edit_duplicate_launch_process'
              : (edit && launch && process)? 'edit_launch_process'
              : (read && edit && duplicate)? 'read_edit_duplicate'
              : (edit && duplicate && launch)? 'edit_duplicate_launch'
              : (duplicate && launch && process)? 'duplicate_launch_process'
              : (read && duplicate && launch)? 'read_duplicate_launch'
              : (read && duplicate && process)? 'read_duplicate_process'
              : (read && edit && process)? 'read_edit_process'
              : (read && edit && launch)? 'read_edit_launch'
              : (read && edit)? 'read_edit'
              : (read && duplicate)? 'read_duplicate'
              : (read && launch)? 'read_launch'
              : (read && process)? 'read_process'
              : (edit && process)? 'edit_process'
              : (edit && launch)? 'edit_launch'
              : (edit && duplicate)? 'edit_duplicate'
              : (duplicate && launch)? 'duplicate_launch'
              : (duplicate && process)? 'duplicate_process'
              : (launch && process)? 'launch_process'
              : (read)? 'read'
              : (edit)? 'edit'
              : (duplicate)? 'duplicate'
              : (launch)? 'launch'
              : (process)? 'process':'empty';
          }

          function isCheckObjectValueTrue(data) {
            return Object.keys(angular.copy(data)).filter(function (key) { return data[key] === true; });
          }

          /**
           * event handler when the task assignee has been changed
           * @type {*|(function())}
           */
          unregisterUpdatedTaskCollaboratorsListener = $rootScope.$on(TFY_EVENTS.TASK.ASSIGNEE.UPDATED, function (event, runData) {
            $ctrl.process.collaborators = runData.collaborators;
            getActiveProcessPermissions();
          });
        }
    });
})();