/**
 * @ngdoc Directive
 * @name tallyfy.tfyBranding
 * @description change buttons, links, form field, borders
 * and circles etc colors of application
 * @restrict 'A'
 * @element ANY
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('tfyBranding', tfyBranding);
  /*@ngInject*/
  function tfyBranding(_, $rootScope, USER_STATE, customBrandingService, TFY_EVENTS, OrganizationsService, $log, Helper, BeaconService, $timeout) {
    return {
      restrict: 'A',
      link: function ($scope) {
        var unregisterUpdateColorListener,
          unregisterIdentityWatcher = $rootScope.$watchGroup(['identity.default_organization.id', 'identity.guest.organization.branding', 'publicOrganization.branding'], trigger);

        function trigger(newIdentity, oldIdentity) {
          if ($rootScope.userState === USER_STATE.GUEST && (newIdentity[1] && newIdentity[1] !== oldIdentity[1])) {
            var colorBranding = _.get($rootScope, 'identity.guest.organization.branding');
            if (!Helper.isObjectEmpty(colorBranding)) {
              resetStyleSheet();
              changeColor(colorBranding);
            } else {
              resetStyleSheet();
            }
          } else if ($rootScope.userState === USER_STATE.PUBLIC && (newIdentity[2] && newIdentity[2] !== oldIdentity[2])) {
            var colorBranding = _.get($rootScope.publicOrganization, 'branding');
            if (!Helper.isObjectEmpty(colorBranding)) {
              resetStyleSheet();
              changeColor(colorBranding);
            } else {
              resetStyleSheet();
            }
          } else {
            if (newIdentity[0] && (newIdentity[0] !== oldIdentity[0])) {
              resetStyleSheet();
              getCustomBrand();
            } else {
              resetStyleSheet();
            }
          }
        }

        /**
         * get custom colors
         */
        function getCustomBrand() {
          OrganizationsService.getOrgCustomBranding()
            .then(function (response) {
              var colorTheme = _.get(response, 'data');
              !Helper.isObjectEmpty(colorTheme) ? changeColor(colorTheme) : resetStyleSheet();
            }, function () {
              $log.error('Error while get branding color');
            });
        }

        /**
         * remove/reset style sheet
         */
        var resetStyleSheet = function () {
          var brandingStyleSheet = document.getElementById("branding");
          if (brandingStyleSheet) {
            brandingStyleSheet.remove();
            BeaconService.destroy();
            $timeout(function () {
              BeaconService.initializeHSBeacon();
            }, 2000);
          }
        };

        /**
         * set rules
         */
        function changeColor(colors) {
          var rules = _.concat(
            customBrandingService.getBtnRules(colors),
            customBrandingService.getNavRules(colors),
            customBrandingService.getTaskBarRules(colors),
            customBrandingService.getProcessBarRules(colors),
            customBrandingService.getSelectDDRules(colors),
            customBrandingService.getInputFieldsRules(colors),
            customBrandingService.getLinksRules(colors),
            customBrandingService.getCalendarRules(colors),
            customBrandingService.getCheckBoxRadioBtnRules(colors),
            customBrandingService.getDashboardRules(colors),
            customBrandingService.getTasksRules(colors),
            customBrandingService.getTrackerRules(colors),
            customBrandingService.getSettingsRules(colors),
            customBrandingService.getFilterRules(colors),
            customBrandingService.getBlueprintRules(colors),
            customBrandingService.getLaunchRules(colors),
            customBrandingService.getModalRules(colors),
            customBrandingService.getNotificationRules(colors),
            customBrandingService.getOthersRules(colors)
          );
          createStyleElement(rules);
          BeaconService.destroy();
          $timeout(function () {
            BeaconService.initializeHSBeacon(colors.bg_color);
          }, 1000);
        }

        /**
         * create style element and append in head tag
         */
        function createStyleElement(rules) {
          var styleEl = document.createElement('style');

          styleEl.setAttribute("id", "branding");
          styleEl.type = 'text/css';
          styleEl.appendChild(document.createTextNode(""));

          // Append <style> element to <head>
          document.head.appendChild(styleEl);
          var styleSheet = styleEl.sheet;
          for (var i = 0; i < rules.length; i++) {
            var rule = rules[i],
              selector = rule[0],
              propStr = '';
            for (var j = 1; j < rule.length; j++) {
              var prop = rule[j];
              propStr += prop[0] + ': ' + prop[1] + (prop[2] ? ' !important' : '') + (prop[3] ? '' : ';\n');
            }
            // Insert CSS Rule
            styleSheet.insertRule(selector + ' {' + propStr + '}', styleSheet.cssRules.length);
          }
        }

        unregisterUpdateColorListener = $rootScope.$on(TFY_EVENTS.BRANDING.UPDATE_COLOR, function ($event, color) {
          resetStyleSheet();
          if (color.reset === 'yes' && !Helper.isObjectEmpty(color.setTheme)) {
            changeColor(color.setTheme);
          }
        });

        $scope.$on('$destroy', function () {
          unregisterUpdateColorListener();
          unregisterIdentityWatcher();
        });
      }
    };
  }
})();