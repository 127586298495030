/**
 * @ngdoc component
 * @name tallyfy.compactTaskDetail
 * @restrict 'A'
 *
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('compactTaskDetail', {
      templateUrl: 'app/components/compact/components/taskDetail/compact-task-detail.component.html',
      bindings: {
        index: '<',
        isGuest: '<',
        task: '=',
        usersInOrg: '<',
        orgAllUsers: '<?',
        readOnlyMode: '<',
        editMode: '=?',
        process: '<?',
        editForm: '=?',
        qSaveTaskCallback: '&?',
        qDiscardTaskCallback: '&?',
        tasksFiltersConfig: '=',
        taskRootConfig: '=',
        allowGuestsAssigned: '<',
        forceOnboarding: "<?",
        isChatBoxOpen: '<',
        isPublic: '<',
        orgGroups: '<?',
        isPublicProcess: '<',
        metadata: '=',
        foldersList: '<?',
        asModal: '<?',
        roles: '<?',
        close: '&?',
        autoLoadProfileImage: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, FroalaService, $rootScope, $scope, $timeout, $state, blockUI, $analytics, $localStorage, FieldService, AuthPlan, OOTAdvancedSettingsService, CompactTaskService,
          TasksService, $filter, Helper, Growl, DateUtils, ENV_CONFIG, CompactStepService, TranslationService, $uibModal, moment, PLANS, RunsService, DATEFORMAT, USER_STATE,
          FolderService, PublicOrganizationService, StepService, $window, OrganizationsService, ProcessService, MilestoneService, DESCRIPTIONSIZE, COMMON, FavoriteService, DOMService) {

          var $ctrl = this,
            taskThreadsWatcher,
            unregisteredIssueReportedEventHandler,
            unregisteredIssueResolvedEventHandler,
            unregisterUpdateRunListener,
            editTaskEventWatchHandler,
            unRegisterMilestonePopupWatcher,
            taskCompleteEventHandler,
            onFileDeleteHandler,
            editTaskFormFieldsWatchHandler,
            oldTask,
            taskProcess,
            watchSummaryNeedToBeUpdated,
            taskIndexUpdatedWatcherHandler,
            growl = new Growl(),
            koFieldClickHandler,
            contentLanguageChangedHandler,
            blockUI = blockUI.instances.get('task-detail'),
            toolbarButtons = CompactStepService.getBlueprintFroalaToolbar(),
            isAdminMember = false,
            metaTasks,
            formFieldData,
            onAccordionReadyHandler,
            activateAccordionChangeHandler,
            unRegisterAssigneePopupWatcher,
            unRegisterStartDatePopupWatcher,
            unRegisterDueDatePopupWatcher,
            unRegisterSummaryPopupWatcher,
            unRegisterJobPopupWatcher,
            popoverBackdropEl = angular.element('<div id="tde_popover_backdrop" class="task-detail-edit-popover-backdrop"></div>'),
            rightSectionContentAreaEl = angular.element('#rightSectionContentArea'),
            windowElem = angular.element($window);

          /**
           * angularjs lifecycle hook
           */
          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          /**
           * Public properties
           */
          $ctrl.formSubmitted = false;
          $ctrl.moreItems = 0;
          $ctrl.isUnLockedTask = false;
          $ctrl.isOpenPopOver = false;
          $ctrl.isTaskFavoritePopoverOpen = false;
          $ctrl.onSaving = false;
          $ctrl.isShowSaveButton = false;
          $ctrl.subHeadingEditMode = true;
          $ctrl.taskSummary = '';
          $ctrl.cutElementOptions = {
            widthOffset: 118
          };
          $ctrl.completePercent = 0;
          $ctrl.isHover = false;
          $ctrl.iHaveCompleted = false;
          $ctrl.update_dependent_deadlines = true;
          $ctrl.insertVariablesForm = [];
          $ctrl.iAmAssigned = true;
          $ctrl.deletedFormFields = [];
          $ctrl.taskLoading = false;
          $ctrl.nudgeCommentList = TasksService.nudgeCommentList();
          $ctrl.isCompactTaskDetailView = true;
          $ctrl.isUserOnlyAssignee = null;
          $ctrl.activeTab = 'config';
          $ctrl.publiclyAssignees = false;
          $ctrl.disableCompletion = false;
          $ctrl.selectRole = [];
          $ctrl.maxLength = DESCRIPTIONSIZE.taskStepTitle;
          $ctrl.tooltipLimit = COMMON.TITLE_TOOLTIP_LIMIT;
          $ctrl.selectJobRole = [];
          $ctrl.fieldsLoading = true;

          /**
           * Public methods
           */
          $ctrl.onCaptureUpdated = onCaptureUpdated;
          $ctrl.deleteStandaloneTask = deleteStandaloneTask;
          $ctrl.onCommentButtonClick = onCommentButtonClick;
          $ctrl.onCompleteTaskClick = onCompleteTaskClick;
          $ctrl.onTitleUpdate = onTitleUpdate;
          $ctrl.copyLink = copyLink;
          $ctrl.resendTask = resendTask;
          $ctrl.viewProcess = viewProcess;
          $ctrl.editTask = editTask;
          $ctrl.discardChanges = discardChanges;
          $ctrl.onTaskSave = onTaskSave;
          $ctrl.haveAuthority = Helper.checkAccessAuthority;
          $ctrl.onHeadingClick = onHeadingClick;
          $ctrl.onEditableElementClick = onEditableElementClick;
          $ctrl.getButtonClass = getButtonClass;
          $ctrl.getButtonText = getButtonText;
          $ctrl.getButtonIconClass = getButtonIconClass;
          $ctrl.getApprovalButtonIconClass = getApprovalButtonIconClass;
          $ctrl.getApprovalButtonText = getApprovalButtonText;
          $ctrl.getExpiringButtonText = getExpiringButtonText;
          $ctrl.getApprovalButtonClass = getApprovalButtonClass;
          $ctrl.onLinkOOTToProcess = onLinkOOTToProcess;
          $ctrl.onUnlinkOOTFromProcess = onUnlinkOOTFromProcess;
          $ctrl.enquireAboutStatus = enquireAboutStatus;
          $ctrl.toggleIsAssigneeLimited = toggleIsAssigneeLimited;
          $ctrl.onPaneClose = onPaneClose;
          $ctrl.onTagsDataChanged = onTagsDataChanged;
          $ctrl.setShowHideTask = setShowHideTask;
          $ctrl.prepareFroalaConfig = prepareFroalaConfig;
          $ctrl.publicCanDoTaskAction = publicCanDoTaskAction;
          $ctrl.onTitleEditable = onTitleEditable;
          $ctrl.updateDeadline = updateDeadline;
          $ctrl.closePopup = closePopup;
          $ctrl.onClickOpen = onClickOpen;
          $ctrl.guestClosePopup = guestClosePopup;
          $ctrl.onFolderRemoved = onFolderRemoved;
          $ctrl.addTaskToFolders = addTaskToFolders;
          $ctrl.onTaskTypeSelected = onTaskTypeSelected;
          $ctrl.duplicateTask = duplicateTask;
          $ctrl.updateStartAt = updateStartAt;
          $ctrl.isTaskEditingDisabled = isTaskEditingDisabled;
          $ctrl.copyTaskLink = copyTaskLink;
          $ctrl.getAllCompletedBy = getAllCompletedBy;
          $ctrl.onSubHeadingClick = onSubHeadingClick;
          $ctrl.milestoneSelected = milestoneSelected;
          $ctrl.getStageTitle = getStageTitle;
          $ctrl.createNewMilestone = createNewMilestone;
          $ctrl.onLinkOOTToTemplate = onLinkOOTToTemplate;
          $ctrl.onUnlinkOOTFromTemplate = onUnlinkOOTFromTemplate;
          $ctrl.toggleTaskWatcherStar = toggleTaskWatcherStar;
          $ctrl.filterJobs = filterJobs;
          $ctrl.onJobRemoved = onJobRemoved;
          $ctrl.onJobAdded = onJobAdded;

          //Window element resize
          windowElem.on('resize', onWindowResize);

          function onInit() {
            $ctrl.isGuest = $rootScope.userState === USER_STATE.GUEST;
            $ctrl.isPublicProcess = $rootScope.userState === USER_STATE.PUBLIC_PROCESS;
            onWindowResize();
            getAllCompletedBy();
            $ctrl.taskTypes = StepService.getStepTypes();
            $ctrl.selectedStepType = _.find($ctrl.taskTypes, { value: $ctrl.task.task_type });
            $ctrl.organizationId = !$ctrl.isGuest ? _.get($rootScope.identity, 'default_organization.id')
              : _.get($rootScope.identity, 'guest.organization.id');
            if ($ctrl.isPublicProcess) {
              $ctrl.taskSummary = _.get($ctrl.task, 'summary');
              var guestEmail = _.get($localStorage.guest, 'email', '');
              getPubliclyHiddenAssignees(_.get($ctrl.process, 'publicly_hidden_fields'));
              prepareInsertVariables();
              if (guestEmail) {
                $ctrl.isGuestAssigned = _.indexOf($ctrl.task.owners.guests, guestEmail) >= 0;
                $ctrl.isCanCompleteTask = isTaskCompletable($ctrl.task);
              }
              $ctrl.completer = _.get($ctrl.task, 'completer_user[0].full_name', '');
              $ctrl.fieldsLoading = false;
            } else {
              var viewer = $ctrl.isGuest ? _.get($rootScope.identity, 'guest.email', '') : _.get($ctrl.tasksFiltersConfig, 'assignee', $rootScope.identity.id);
              var viewerId = (viewer === 'all') ? void 0 : viewer;
              isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
              $ctrl.viewerId = (!viewerId && !$ctrl.isGuest) ? $rootScope.identity.id : viewerId;
              $ctrl.enquireStatus = !_.includes(_.get($ctrl.task, 'owners.users'), $rootScope.identity.id);
              $ctrl.taskLoading = true;
              var params = { id: _.get($ctrl.task, 'id'), skipNotFound: true },
                withStr = 'activities,rule_executed,threads,run.form_fields,run.ko_form_fields,threads.reactions,step,step.roles,checklist,form_fields,folders,uploaded_at';
              angular.extend(params, { with: withStr + ($ctrl.isGuest ? ',guest_watchers.watcher' : ',member_watchers.watcher') });
              var resources = !$ctrl.isGuest ? TasksService.getTask(angular.extend({
                action_id: _.get($ctrl.task, 'run_id', void 0)
              }, params))
                : TasksService.getGuestTask(params);
              resources.then(function (responses) {
                if ($ctrl.isGuest) {
                  _.set(responses.data, 'current_guest_watchers', getCurrentUserWatcher(responses.data.guest_watchers.data));
                } else {
                  _.set(responses.data, 'current_member_watchers', getCurrentUserWatcher(responses.data.member_watchers.data));
                }
                $ctrl.fieldsLoading = false;
                if ($ctrl.isGuest) {
                  $ctrl.task = _.merge($ctrl.task, responses.data);
                } else {
                  angular.extend($ctrl.task, _.omit(responses.data, ['taskdata']));
                }
                $ctrl.descriptionTranslateTo = TranslationService.getMyContentLanguage();
                $ctrl.taskLoading = false;
                $ctrl.isCanCompleteTask = isTaskCompletable($ctrl.task);
                setCompleteProgress();
                $ctrl.taskSummary = _.get($ctrl.task, 'summary');
                oldTask = angular.copy($ctrl.task);
                prepareInsertVariables();
                $ctrl.task.summary = prepareEditorData(_.get($ctrl.task, 'summary'));
                if (_.get($ctrl.task, 'step.data.owner') === 'run_starter' && _.get($ctrl.task, 'step.data.roles.data', []).length) {
                  var jobRoles = _.get($ctrl.task, 'step.data.roles.data', []);
                  for (var i = 0; i < jobRoles.length; i++) {
                    var jobRole = _.find($ctrl.roles, { id: jobRoles[i].org_role_id });
                    if (jobRole) {
                      $ctrl.selectJobRole.push({
                        id: jobRoles[i].org_role_id,
                        title: jobRole.title,
                        role_id: jobRoles[i].id
                      });
                      updateJobString(jobRole);
                    }
                  }
                }
                if (_.get($ctrl.task, 'checklist_id') && ($rootScope.userState === USER_STATE.MEMBER)) {
                  updateTaskTemplate();
                }
                if (!_.get($ctrl.task, 'run_id')) {
                  $ctrl.task = _.omit($ctrl.task, ['run', 'run_status']);
                }
                if (!$ctrl.isGuest) {
                  getStageData();
                }
              });
              if (!$ctrl.process && !$ctrl.isGuest && $ctrl.task && $ctrl.task.run) {
                $ctrl.progessBar = {
                  'progessBarClass': RunsService.getBarClass($ctrl.task.run.data),
                  'progessBarRunStatus': getRunStatus($ctrl.task.run.data),
                  'progessBarPercent': $ctrl.task.run.data.whole_progress.percent,
                  'progessBarComplete': $ctrl.task.run.data.whole_progress.complete,
                  'progessBarTotal': $ctrl.task.run.data.progress.total
                };
              }
              $ctrl.isAssigneeLimited = _.get($ctrl.task, 'max_assignable', 0) > 0;
              $ctrl.task.status === 'auto-skipped' ? _.set($ctrl.taskRootConfig, 'showHiddenTasks', true) : angular.noop;
              $ctrl.invalidAssignedOnly = !isAdminMember && ($ctrl.task.everyone_must_complete || _.get($ctrl.task, 'step.data.can_complete_only_assignees')) ? !TasksService.checkIsTaskCompletable($ctrl.task, $ctrl.orgGroups || []) : false;
              $ctrl.allAssigneesCompletionPending = !!(($ctrl.task.everyone_must_complete) && $ctrl.task.status !== 'completed' && _.includes(_.get($ctrl.task, 'owners.users'), $rootScope.identity.id) && !_.includes(_.get($ctrl.task, 'completion_progress.waiting.users'), $rootScope.identity.id));
              $ctrl.iAmAssigned = _.indexOf(_.get($ctrl.task, 'owners.users'), $rootScope.identity.id || $ctrl.viewerId) !== -1;
              $ctrl.isUserOnlyAssignee = checkIfUserIsOnlyAssignee();
              $ctrl.starterName = (_.find($ctrl.orgAllUsers, { id: $ctrl.task.starter_id }) || {}).text;
              $ctrl.dateAdd = DateUtils.toLocal(moment($ctrl.task.created_at)).format(DATEFORMAT.DEFAULT);
              $ctrl.isArchivedStatus = _.get($ctrl.task, 'run.data.status') === 'archived';
              $ctrl.isCurrentMemberInGroup = isCurrentMemberInGroupCheck($ctrl.task.owners.groups);
              $ctrl.isClaimTask = ((_.get($ctrl.task, 'owners.users', []).length > 1) || _.get($ctrl.task, 'owners.groups', []).length > 1) && (!$ctrl.task.everyone_must_complete) && ($ctrl.iAmAssigned || $ctrl.isCurrentMemberInGroup);
            }
            koTaskAssigneeCheck();
            getPubliclyHiddenAssignees(_.get($ctrl.process, 'publicly_hidden_fields'));
            $ctrl.fromEmail = OrganizationsService.getEmailPreferences();
            getProperties();
          }

          /**
           * @ngdoc method
           * @name onChanges
           *
           * @description
           * angularjs life cycle hook
           */
          function onChanges() { }

          function getCurrentUserWatcher(data) {
            return FavoriteService.getCurrentUserWatcher(data);
          }

          function getAnotherUserWatcher(data) {
            return FavoriteService.getAnotherUserWatcher(data);
          }

          /**
           * @ngdoc method
           * @name setShowHideTask
           * @param {event} event
           *
           * @description
           * task show and hide manually
           */
          function setShowHideTask(event) {
            event.stopPropagation();
            if (!$ctrl.task.is_oneoff_task) {
              CompactTaskService.taskComment($ctrl.task).then(function () {
                var updateTaskStatus = $ctrl.task.status === "auto-skipped" ? 'show' : 'hide';
                TasksService.setShowHideTask({
                  org_id: $ctrl.organizationId,
                  task_id: _.get($ctrl.task, 'id'),
                  run_id: _.get($ctrl.task, 'run_id', void 0),
                  action: updateTaskStatus
                }).then(function (res) {
                  angular.extend($ctrl.task, {
                    status: _.get(res.data, 'status'),
                    status_label: _.get(res.data, 'status_label')
                  });
                  $ctrl.isOpenPopOver = $ctrl.isTaskFavoritePopoverOpen = false;
                  growl.success($filter('translate')('tasks.general.message.' + updateTaskStatus), {
                    referenceId: 'global',
                    disableIcons: true,
                    disableCloseButton: true
                  });
                  $rootScope.$emit('RUN:TASK_UPDATED');
                });
              });
            }
          }

          function toggleTaskWatcherStar($event) {
            $event.stopImmediatePropagation();
            if (!$ctrl.haveAuthority()) {
              return;
            }
            var growlMessages = Growl.getAllMessages('global'), showAlertDelay = 0,
              active = ($ctrl.isGuest ? getCurrentUserWatcher(_.get($ctrl.task, 'guest_watchers.data')).length>0
                : getCurrentUserWatcher(_.get($ctrl.task, 'member_watchers.data')).length>0),
              action = active ? 'un-starred' : 'starred';
            if (angular.copy(growlMessages.length) > 0) {
              showAlertDelay = 500;
              Growl.clearAllMessages('global');
            }
            if (!active) {
              if ($ctrl.isGuest) {
                blockUI.start();
                FavoriteService.addGuestFavorite(_.get($rootScope,'identity.guest.guest_code'), {
                 objId: _.get($ctrl.task, 'id'),
                 objectType: 'task'
                }).then(function (res) {
                 blockUI.stop();
                 $timeout(function () {
                   growl.success($filter('translate')('runs.dashboard.taskAction.' + action), {
                     referenceId: 'global',
                     disableIcons: true,
                     disableCloseButton: true
                   });
                 }, showAlertDelay);
                 $ctrl.task.guest_watchers.data.push(res.data);
                 $ctrl.task.current_guest_watchers.push(res.data);
                }, function (error) {
                  blockUI.stop();
                  if (error.status === 403) {
                    $timeout(function () {
                      growl.error(error.data, {
                        referenceId: 'global',
                        disableIcons: true,
                        disableCloseButton: true
                      });
                    }, showAlertDelay);
                  }
                });
              } else {
                blockUI.start();
                FavoriteService.addFavorite({
                  userId: _.get($rootScope, 'identity.id'),
                  objId: _.get($ctrl.task, 'id'),
                  objectType: 'task'
                }).then(function (res) {
                  blockUI.stop();
                  $timeout(function () {
                    growl.success($filter('translate')('runs.dashboard.taskAction.' + action), {
                      referenceId: 'global',
                      disableIcons: true,
                      disableCloseButton: true
                    });
                  }, showAlertDelay);
                  $ctrl.task.member_watchers.data.push(_.get(res, 'data'));
                  $ctrl.task.current_member_watchers.push(_.get(res, 'data'));
                }, function (error) {
                  blockUI.stop();
                  if (error.status === 403) {
                    $timeout(function () {
                      growl.error(error.data, {
                        referenceId: 'global',
                        disableIcons: true,
                        disableCloseButton: true
                      });
                    }, showAlertDelay);
                  }
                });
              }
            } else {
              if ($ctrl.isGuest) {
                var watcherData = angular.copy(_.first(getCurrentUserWatcher(_.get($ctrl.task, 'guest_watchers.data')))),
                  anotherWatcherData = getAnotherUserWatcher($ctrl.task.guest_watchers.data);
                _.set($ctrl.task, 'guest_watchers.data', anotherWatcherData);
                _.set($ctrl.task, 'current_guest_watchers', anotherWatcherData);
                $timeout(function () {
                  growl.success($filter('translate')('runs.dashboard.taskAction.' + action), {
                    referenceId: 'global',
                    disableIcons: true,
                    disableCloseButton: true
                  });
                }, showAlertDelay);
                FavoriteService.removeGuestFavorite(
                  _.get($rootScope,'identity.guest.guest_code'),
                  _.get(watcherData, 'id')
                ).then(function () {
                  blockUI.stop();
                });
              } else if (!$ctrl.isGuest) {
                var watcherData = angular.copy(_.first(getCurrentUserWatcher(_.get($ctrl.task, 'member_watchers.data')))),
                  anotherWatcherData = getAnotherUserWatcher($ctrl.task.member_watchers.data);;
                _.set($ctrl.task, 'member_watchers.data', anotherWatcherData);
                _.set($ctrl.task, 'current_member_watchers', []);
                $timeout(function () {
                  growl.success($filter('translate')('runs.dashboard.taskAction.' + action), {
                    referenceId: 'global',
                    disableIcons: true,
                    disableCloseButton: true
                  });
                }, showAlertDelay);
                FavoriteService.removeFavorite(_.get(watcherData, 'id'));
                blockUI.stop();
              } else {
                blockUI.stop();
              }
            }
          }

          function getLatestTaskData(params) {
            blockUI.start();
            $ctrl.onloadingSummary = true;
            var resource = $ctrl.isGuest
              ? TasksService.getGuestTask(angular.extend({ action_id: $ctrl.task.id }, params))
              : TasksService.getTask({
                action_id: $ctrl.task.is_oneoff_task ? void 0 : _.get($ctrl.task, 'run_id'),
                id: $ctrl.task.id,
                with: 'activities,rule_executed,threads,run.form_fields,run.ko_form_fields,threads.reactions,step,step.roles,checklist,form_fields,folders,uploaded_at'
              });
            resource.then(function (res) {
              blockUI.stop();
              angular.extend($ctrl.task, res.data);
              oldTask = angular.copy($ctrl.task);
              $ctrl.task.summary = prepareEditorData(_.get($ctrl.task, 'summary'));
              $ctrl.onloadingSummary = false;
              $ctrl.task.summaryNeedToBeUpdated = void 0;
              $rootScope.$emit('TASK:SUMMARY_UPDATED', { task: $ctrl.task });
            });
          }

          function onDestroy() {
            onFileDeleteHandler();
            taskThreadsWatcher();
            taskIndexUpdatedWatcherHandler();
            unregisteredIssueReportedEventHandler();
            unregisteredIssueResolvedEventHandler();
            taskCompleteEventHandler();
            guestAssignEditModeEventHandler();
            watchSummaryNeedToBeUpdated();
            rightPaneEditModeOpenEventWatchHandler();
            editTaskEventWatchHandler();
            editTaskFormFieldsWatchHandler();
            unregisterUpdateRunListener();
            if (koFieldClickHandler) {
              koFieldClickHandler();
            }
            if (activateAccordionChangeHandler) {
              activateAccordionChangeHandler();
            }
            if (onAccordionReadyHandler) {
              onAccordionReadyHandler();
            }
            contentLanguageChangedHandler();
            unRegisterAssigneePopupWatcher();
            unRegisterStartDatePopupWatcher();
            unRegisterDueDatePopupWatcher();
            unRegisterSummaryPopupWatcher();
            togglePopoverBackdrop(false);
            unRegisterMilestonePopupWatcher();
            unRegisterJobPopupWatcher();
            windowElem.off('resize', onWindowResize);
          }

          /**
           * @ngdoc method
           * @name getAllCompletedBy
           *
           * @description
           * on collect list of all completed by user and guest
           */
          function getAllCompletedBy() {
            var orgUser, completedBy = [], completed_by = _.get($ctrl.task, 'completion_progress.completed_by');
            orgUser = $ctrl.isGuest ? _.get($ctrl.task, 'owners.users') : $ctrl.orgAllUsers;
            if (_.get(completed_by, 'users', []).length > 0) {
              _.forEach(completed_by.users, function (userId) {
                completedBy.push(_.find(orgUser, { id: userId }));
              });
            }
            completedBy = _.concat(_.map(completedBy, 'full_name'), _.get(completed_by, 'guests', []));
            $ctrl.completedBy = completedBy.join(', ');
          }

          /**
           * @ngdoc method
           * @name onCommentButtonClick
           * @param {*} type
           *
           * @description
           * click on comment button scroll open comment box
           */
          function onCommentButtonClick(type) {
            type ? $ctrl.open = !$ctrl.open : $ctrl.open = false;
            var data = {
              comment: _.get(type, 'text', ''),
              users: $ctrl.orgAllUsers,
              assignUser: _.get($ctrl.task, 'owners')
            };
            $rootScope.$emit('FROALA_BOX_OPEN:ADD_COMMENT', data);
          }

          function onRejectTaskClick() {
            $ctrl.onRejectSaving = true;
            CompactTaskService.taskReject($ctrl.task).then(function (res) {
              angular.extend($ctrl.task, res.task);
              setCompleteProgress();
              trackEvent('Task reject', {
                'form-fields': _.get(res.task, 'form_fields', []).length ? 'yes' : 'no'
              });
              getAllCompletedBy();
              $ctrl.onRejectSaving = false;
            }, function () {
              $ctrl.onRejectSaving = false;
            });
          }

          /**
           * @ngdoc method
           * @name onCompleteTaskClick
           *
           * @description
           * on complete button click
           * @param {*} action
           * @param {event} $event
           */
          function onCompleteTaskClick($event, action) {
            if (CompactTaskService.isCaptureUpdateInProgress || CompactTaskService.isCaptureUpdateInQueue) {
              action === 'reject' ? $ctrl.onRejectSaving = true : $ctrl.onSaving = true;
              $timeout(function () {
                onCompleteTaskClick($event, action);
              }, 500);
            } else {
              $ctrl.formSubmitted = true;
              var stepCaptures = getStepCaptures(),
                isHasEmptyRequiredFields = CompactTaskService.validateCaptures(stepCaptures),
                isHasExceededTextChars = CompactTaskService.haveTextCharsExceeded(stepCaptures);
              if ((isHasExceededTextChars || isHasEmptyRequiredFields) && !($ctrl.task.status === 'completed' || $ctrl.iHaveCompleted)) {
                $rootScope.$emit('COMPACT:FORM_VALIDATION_ERROR', { stage: _.find($ctrl.processStages, { id: $ctrl.task.stage_id }) });
                action === 'reject' ? $ctrl.onRejectSaving = false : $ctrl.onSaving = false;
                // Timeout added to allow the bindings to be updated in render-form to validate form fields again
                $timeout(function () {
                  $ctrl.formSubmitted = false;
                }, 100);
                return;
              }
              if ($ctrl.disableCompletion && !($ctrl.task.status === 'completed' || $ctrl.iHaveCompleted)) {
                $ctrl.onSaving = false;
                return;
              }
              if ($ctrl.task.status === 'completed' || $ctrl.iHaveCompleted) {
                inCompleteTask(action);
              } else {
                if (action === "reject") {
                  onRejectTaskClick();
                } else {
                  completeTask(action);
                }
              }
            }
          }

          function mapProcessPrerunValue() {
            var prerun = {};
            for (var key in $ctrl.task.run.data.prerun) {
              var data = _.find($ctrl.task.run.data.ko_form_fields.data, { id: key }) || {};
              if (data.field_type === 'table') {
                var value = $ctrl.task.run.data.prerun[key];
                if (value && value.length) {
                  if (Helper.isObjectEmpty(prerun[key])) {
                    prerun[key] = [];
                  }
                  for (var i = 0; i < value.length; i++) {
                    prerun[key].push(
                      !Helper.isObjectEmpty(value) && !Helper.isObjectEmpty(value[i]) && value[i].id
                        ? value[i]
                        : {
                          id: data.id,
                          label: data.label,
                          value: value[i]
                        }
                    );
                  }
                }
              } else {
                prerun[key] = $ctrl.task.run.data.prerun[key];
              }
            }
            return prerun;
          }

          function updatePrerunField(field) {
            if (!$ctrl.task.run.data.prerun) {
              $ctrl.task.run.data.prerun = {};
            }
            $ctrl.task.run.data.prerun[field.id] = FieldService.getFieldValue(field);
            var request = $ctrl.isGuest
              ? RunsService.updateGuestPrerun({
                runId: $ctrl.task.run.data.id,
                skipAuthToken: true
              }, {
                run_id: $ctrl.task.run.data.id,
                category: "prerun",
                form_id: field.id,
                alias: field.alias,
                field_type: field.field_type,
                form_value: field.value
              })
              : RunsService.update({ id: $ctrl.task.run.data.id }, { prerun: mapProcessPrerunValue() });
            request.then(function (res) {
              angular.extend($ctrl.task.run.data, { prerun: res.data.prerun });
              $rootScope.$broadcast('CAPTURE:UPDATE', { updatedTask: 'ko-form', updatedCapture: field });
            });
          }

          function taskUpdateSuccess(field) {
            if ($ctrl.process) {
              if ($ctrl.isGuest && $ctrl.isKoTaskAssignee) {
                var tempEl = angular.element('<div>').html($ctrl.task.summary),
                  koAssigneeElements = tempEl[0].getElementsByTagName('ko-field-assignee');
                if (koAssigneeElements && koAssigneeElements.length && field) {
                  var koField = _.find(_.get($ctrl.process, 'checklist.data.prerun', []), { alias: koAssigneeElements[0].dataset.koFieldAlias.replace(/'/g, '') });
                  angular.extend(koField, { value: field.value });
                  updatePrerunField(koField);
                }
              }
              $rootScope.$broadcast('CAPTURE:UPDATE', { updatedTask: $ctrl.task, updatedCapture: field, isKoTaskAssignee: $ctrl.isKoTaskAssignee });
            } else {
              var tempEl = angular.element('<div>').html($ctrl.task.summary),
                koAssigneeElements = tempEl[0].getElementsByTagName('ko-field-assignee');
              if (koAssigneeElements && koAssigneeElements.length && field) {
                var koField = _.find(
                  $ctrl.isGuest ? _.get(formFieldData, 'ko_form_fields', []) : _.get(formFieldData, 'ko_form_fields.data', []),
                  { alias: koAssigneeElements[0].dataset.koFieldAlias.replace(/'/g, '') }
                );
                angular.extend(koField, { value: field.value });
                updatePrerunField(koField);
              }
            }
            CompactTaskService.isCaptureUpdateInProgress = false;
            if ($ctrl.isKoTaskAssignee && !Helper.isObjectEmpty(FieldService.getFieldValue(field)) && $ctrl.task.status !== 'completed') {
              $ctrl.onCompleteTaskClick();
            }
          }

          /**
           * @ngdoc method
           * @name taskUpdateError
           * @param {*} err
           *
           * @description
           * update task response error handler
           */
          function taskUpdateError(err) {
            if (err.status === 422) {
              $ctrl.errorFields = _.get(err, 'data.data.errors');
            }
            CompactTaskService.isCaptureUpdateInProgress = false;
          }

          /**
           * @ngdoc method
           * @name onCaptureUpdated
           * @param {*} field
           *
           * @description
           * on capture field updated handler
           */
          function onCaptureUpdated(field) {
            var isArchivedStatus = _.get($ctrl.task, 'run.data.status') === 'archived';
            if (isArchivedStatus) {
              return;
            }
            $ctrl.task.taskdata[field.id] = FieldService.getFieldValue(field);
            var isHasEmptyRequiredFields = CompactTaskService.validateCaptures([field]), isHasExceededTextChars = CompactTaskService.haveTextCharsExceeded([field]), validationInfo = CompactTaskService.getShortTextValidationInfo(field);
            if ((isHasExceededTextChars || isHasEmptyRequiredFields || (validationInfo && !validationInfo.isValid)) && !($ctrl.task.status === 'completed' || $ctrl.iHaveCompleted)) {
              $rootScope.$emit('COMPACT:FORM_VALIDATION_ERROR', { stage: _.find($ctrl.processStages, { id: $ctrl.task.stage_id }) });
              return;
            }
            if (CompactTaskService.isCaptureUpdateInProgress) {
              CompactTaskService.isCaptureUpdateInQueue = true;
              $timeout(function () {
                onCaptureUpdated(field);
                CompactTaskService.isCaptureUpdateInQueue = false;
              }, 100);
            } else {
              CompactTaskService.isCaptureUpdateInProgress = true;
              var OOTTaskPayload;
              if ($ctrl.task.is_oneoff_task && !$ctrl.task.run_id) {
                OOTTaskPayload = angular.extend(_.omit($ctrl.task, ['started_at']), {
                  name: $ctrl.task.name,
                  deadline: DateUtils.toUTC(moment($ctrl.task.deadline_unformatted)).format(DATEFORMAT.DEFAULT),
                  taskdata: $ctrl.task.taskdata,
                  owners: $ctrl.task.owners
                });
              }
              var taskPayload = { taskdata: $ctrl.task.taskdata },
                resource = $ctrl.isGuest || ($ctrl.isPublicProcess && $ctrl.isGuestAssigned)
                  ? CompactTaskService.updateGuestTask($ctrl.task, taskPayload)
                  : $ctrl.task.is_oneoff_task && !$ctrl.task.run_id
                    ? CompactTaskService.updateStandaloneTask($ctrl.task, OOTTaskPayload)
                    : CompactTaskService.updateTask($ctrl.task, taskPayload);
              resource.then(function (response) {
                if (_.get(response, 'data.summary')) {
                  $ctrl.task.summary = response.data.summary;
                }
                taskUpdateSuccess(field);
              }, taskUpdateError);
            }
          }

          /**
           * @ngdoc method
           * @private
           * @name isTaskCompletable
           * @param {*} task
           * @description
           * check if task is completable
           */
          function isTaskCompletable(task) {
            return CompactTaskService.isTaskCompletable(task, $ctrl.orgGroups || []);
          }

          /**
           * @ngdoc method
           * @private
           * @name completeTask
           * @description
           * complete a task
           * @param {*} action
           */
          function completeTask(action) {
            action === 'reject' ? $ctrl.onRejectSaving = true : $ctrl.onSaving = true;
            var override_user;
            if ($ctrl.task.everyone_must_complete && isAdminMember && $ctrl.enquireStatus) {
              var waitingUsers = _.get($ctrl.task, 'completion_progress.waiting.users', []),
                currentUser = _.get($rootScope, 'identity.id');
              override_user = _.includes(waitingUsers, currentUser) ? currentUser
                : _.first(waitingUsers);
            }
            CompactTaskService.taskComplete($ctrl.task, action, $ctrl.isGuest, true, override_user).then(function (res) {
              $ctrl.completeProgressCaption ? getSingleTask(res.task) : angular.extend($ctrl.task, res.task);
              setCompleteProgress();
              var stepCaptures = (_.get($ctrl.task, 'form_fields.data', []) || []); // No need to evaluate required fields / values here
              trackEvent('Task completed', {
                category: $ctrl.isGuest ? 'Task completed by guest' : 'Task completed by member',
                'form-fields': stepCaptures.length ? 'yes' : 'no'
              });
              getAllCompletedBy();
              action === 'reject' ? $ctrl.onRejectSaving = false : $ctrl.onSaving = false;
              $ctrl.formSubmitted = false;
            }, function () {
              action === 'reject' ? $ctrl.onRejectSaving = false : $ctrl.onSaving = false;
              $ctrl.formSubmitted = false;
            });
          }

          /**
           * @ngdoc method
           * @private
           * @name getSingleTask
           * @description
           * After complete a task get single task data
           * @param {Object} task
           */
          function getSingleTask(task) {
            var params = { with: 'threads' };
            var resource = $ctrl.isGuest
              ? TasksService.getGuestTask(angular.extend({
                action_id: task.id
              }, params)) : TasksService.getTask(angular.extend({
                action_id: task.is_oneoff_task ? void 0 : task.run_id,
                id: task.id
              }, params));
            resource.then(function (response) {
              angular.extend($ctrl.task, response.data);
            }, function () { });
          }

          /**
           * @ngdoc method
           * @private
           * @name inCompleteTask
           *
           * @description
           * re-open a task
           * @param {*} action
           */
          function inCompleteTask(action) {
            action === 'reject' ? $ctrl.onRejectSaving = true : $ctrl.onSaving = true;
            CompactTaskService.taskReopen($ctrl.task).then(function (res) {
              angular.extend($ctrl.task, res.task);
              setCompleteProgress();
              trackEvent('Task re-opened', {
                'form-fields': _.get(res.task, 'form_fields', []).length ? 'yes' : 'no'
              });
              getAllCompletedBy();
              action === 'reject' ? $ctrl.onRejectSaving = false : $ctrl.onSaving = false;
              $ctrl.formSubmitted = false;
            }, function () {
              action === 'reject' ? $ctrl.onRejectSaving = false : $ctrl.onSaving = false;
              $ctrl.formSubmitted = false;
            });
          }

          /**
           * @ngdoc methods
           * @name onTitleUpdate
           * @param {*} subHeading
           *
           * @description
           * update task title
           */
          function onTitleUpdate(subHeading) {
            $ctrl.task.name = (!subHeading) ? oldTask.title : subHeading;
          }

          /**
           * @ngdoc method
           * @param {*} $event
           * @description
           * copy guest link to clipboard
           */
          function copyLink($event) {
            var owners = _.get($ctrl.task, 'owners', {});
            if (owners.guests.length) {
              if (owners.guests.length > 1) {
                $uibModal.open({
                  component: 'copyGuestUrl',
                  windowClass: 'copy-guest-url-modal',
                  backdrop: 'static',
                  resolve: {
                    owners: function () {
                      return owners;
                    }
                  }
                });
              } else if (owners.guests.length === 1) {
                var text = $ctrl.task.owners.taskUrls[0];
                Helper.copyToClipboard(text, 'guestUrl', $event);
              }
            } else {
              var text = $ctrl.task.is_oneoff_task ?
                ENV_CONFIG.TYFY_MAIN_URL + $state.href('home', { org_id: $ctrl.organizationId, activeTask: $ctrl.task.id }) :
                ENV_CONFIG.TYFY_MAIN_URL + $state.href('run.view', { org_id: $ctrl.organizationId, id: $ctrl.task.run_id, activeTask: $ctrl.task.id });
              Helper.copyToClipboard(text, 'taskLink', $event);
            }
            $ctrl.isOpenPopOver = $ctrl.isTaskFavoritePopoverOpen = false;
          }

          function resendTask(event) {
            if (!Helper.checkAccessAuthority())
              return;
            Growl.clearAllMessages('global');
            event.stopPropagation();
            var params = {
              id: $ctrl.task.id,
              sub_action: 'email'
            };

            if ($ctrl.task.run_id) {
              angular.extend(params, {
                action: 'runs',
                action_id: $ctrl.task.run.data.id
              });
            }

            blockUI.start();
            TasksService.resendTask(params)
              .then(function () {
                blockUI.stop();
                trackEvent('Task edited', { action: 're-send' });
                growl.success($filter('translate')('tasks.general.resendTask'), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
                $ctrl.isOpenPopOver = $ctrl.isTaskFavoritePopoverOpen = false;
              }, function () {
                blockUI.stop();
              });
          }

          function viewProcess(event) {
            event.stopImmediatePropagation();
            $ctrl.isOpenPopOver = $ctrl.isTaskFavoritePopoverOpen = false;
            $state.go('run.view', {
              id: $ctrl.task.run_id,
              activeTask: $ctrl.task.id
            });
          }

          function editTask(event, activeTab) {
            if ($ctrl.editMode) {
              return;
            }
            event.stopImmediatePropagation();
            $ctrl.isOpenPopOver = $ctrl.isTaskFavoritePopoverOpen = false;
            if ($ctrl.isGuest || !TasksService.isTaskEditable({ task: $ctrl.task, owners: $ctrl.task.owners }, $ctrl.orgGroups)) {
              return;
            }
            oldTask = angular.copy($ctrl.task);
            $ctrl.editMode = true;
            $ctrl.activeTab = !activeTab ? 'config' : activeTab;
          }

          function discardChanges(noClosePopup) {
            $ctrl.qDiscardTaskCallback({
              args: {
                task: $ctrl.task,
                oldTask: oldTask
              }
            }).then(function () {
              $ctrl.isShowSaveButton = false;
              oldTask = angular.copy($ctrl.task);
              $ctrl.task.title = _.get(oldTask, 'title', '');
              resetEditMode(noClosePopup);
            });
          }

          function onTaskTypeSelected(type, isAutoSave) {
            $ctrl.task.task_type = type.value;
            $ctrl.selectedStepType = type;
            $ctrl.isShowSaveButton = true;
            if (isAutoSave) {
              onTaskSave({}, false);
            }
          }

          function onTaskSave(e, reInitAssignee) {
            $ctrl.onloadingSummary = true;
            var diff = _.get($ctrl.task, 'step.data.is_soft_start_date') ? true : TasksService.getDiffInDate($ctrl.task.started_at, $ctrl.task.deadline);
            if (diff) {
              blockUI.start();
              $ctrl.qSaveTaskCallback({
                args: {
                  task: angular.extend($ctrl.task, { summary: $ctrl.task.original_summary, title: $ctrl.task.original_title }),
                  update_dependent_deadlines: $ctrl.update_dependent_deadlines,
                  assigneeOnly: reInitAssignee,
                  deletedOOTFields: $ctrl.deletedFormFields
                }
              }).then(function (res) {
                getLatestTaskData();
                $timeout(function () {
                  !$ctrl.isGuest ? $ctrl.isShowSaveButton = false : angular.noop();
                }, 100);
                if (_.get(res, 'task') && res.isValid) {
                  $ctrl.deletedFormFields = [];
                  var hasDeadlineChanged = oldTask ? !(moment(oldTask.deadline).isSame(moment(res.task.deadline))) : angular.noop(),
                    haveAssigneesChanged = oldTask ? !_.isEqual(oldTask.owners.users.sort(), res.task.owners.users.sort()) || !_.isEqual(oldTask.owners.guests.sort(), res.task.owners.guests.sort()) || !_.isEqual(oldTask.owners.groups.sort(), res.task.owners.groups.sort()) : angular.noop();
                  if (hasDeadlineChanged) {
                    trackEvent('Task deadline changed', { category: $ctrl.task.is_oneoff_task ? 'Task deadline changed - OOT' : 'Task deadline changed - process task' });
                  }
                  if (haveAssigneesChanged) {
                    checkAssigneeDiff(res);
                  }
                  if (oldTask.title !== res.task.title) {
                    trackEvent('Task title edited', { category: $ctrl.task.is_oneoff_task ? 'Task title edited - OOT' : 'Task title edited - process task' });
                  }
                  angular.extend($ctrl.task, res.task);
                  $ctrl.taskSummary = _.get($ctrl.task, 'summary');
                  $ctrl.task.summary = prepareEditorData(_.get($ctrl.task, 'summary'));
                  $ctrl.titleEditable = false;
                  isTaskCompletable($ctrl.task);
                  oldTask = angular.copy($ctrl.task);
                  setCompleteProgress();
                  trackEvent('Task edited', {
                    type: !$ctrl.task.is_oneoff_task
                      ? 'process '
                      : 'standalone',
                    action: 'edit'
                  });
                  $rootScope.$emit('COMMENT_TAG:RESET_TAG', { stage: $ctrl.task.stage_id && $ctrl.process ? _.find($ctrl.processStages, { id: $ctrl.task.stage_id }) : void 0 });
                  $rootScope.$emit('COMPACT_TASK:UPDATE', { task: $ctrl.task, stage: $ctrl.task.stage_id && $ctrl.process ? _.find($ctrl.processStages, { id: $ctrl.task.stage_id }) : void 0 });
                  if (hasDeadlineChanged) {
                    $rootScope.$emit('COMPACT_TASK:UPDATE_DATA', { stage: $ctrl.task.stage_id && $ctrl.process ? _.find($ctrl.processStages, { id: $ctrl.task.stage_id }) : void 0, updatedTask: $ctrl.task, reFetchUpdatedTask: true, update_dependent_deadlines: $ctrl.update_dependent_deadlines, fetchWithParams: true });
                  } else if (haveAssigneesChanged) {
                    $rootScope.$emit('COMPACT_TASK:UPDATE_DATA', { stage: $ctrl.task.stage_id && $ctrl.process ? _.find($ctrl.processStages, { id: $ctrl.task.stage_id }) : void 0, updatedTask: $ctrl.task, reFetchUpdatedTask: true, update_dependent_deadlines: false, fetchWithParams: true });
                  }
                  $ctrl.enquireStatus = !_.includes(_.get($ctrl.task, 'owners.users'), $rootScope.identity.id);
                  $ctrl.iAmAssigned = _.indexOf($ctrl.task.owners.users, $rootScope.identity.id || $ctrl.viewerId) !== -1;
                  $ctrl.isUserOnlyAssignee = checkIfUserIsOnlyAssignee();
                  $ctrl.isCurrentMemberInGroup = isCurrentMemberInGroupCheck($ctrl.task.owners.groups);
                  $ctrl.isClaimTask = (($ctrl.task.owners.users.length > 1) || $ctrl.task.owners.groups.length) && (!$ctrl.task.everyone_must_complete) && ($ctrl.iAmAssigned || $ctrl.isCurrentMemberInGroup);
                  resetEditMode();
                }
                blockUI.stop();
              }, function () {
                blockUI.stop();
              });
            }
          }

          function checkAssigneeDiff(res) {
            var oldUsers = oldTask.owners.users.sort(), newUsers = res.task.owners.users.sort(), oldGuests = oldTask.owners.guests.sort(), newGuests = res.task.owners.guests.sort();
            if (!_.isEqual(oldUsers, newUsers)) {
              for (var i = 0; i < newUsers.length; i++) {
                if (_.indexOf(oldUsers, newUsers[i]) === -1) {
                  trackEvent('Task assignee added', { category: 'Task assigned by ' + ($ctrl.isGuest ? 'guest' : 'member') + ' to member' });
                }
              }
              for (var i = 0; i < oldUsers.length; i++) {
                if (_.indexOf(newUsers, oldUsers[i]) === -1) {
                  trackEvent('Task assignee removed', { category: $ctrl.task.is_oneoff_task ? 'Task assignee removed - OOT' : 'Task assignee removed - process task' });
                }
              }
            }
            if (!_.isEqual(oldGuests, newGuests)) {
              for (var i = 0; i < newGuests.length; i++) {
                if (_.indexOf(oldGuests, newGuests[i]) === -1) {
                  trackEvent('Task assignee added', { category: 'Task assigned by ' + ($ctrl.isGuest ? 'guest' : 'member') + ' to guest' });
                }
              }
              for (var i = 0; i < oldGuests.length; i++) {
                if (_.indexOf(newGuests, oldGuests[i]) === -1) {
                  trackEvent('Task assignee removed', { category: $ctrl.task.is_oneoff_task ? 'Task assignee removed - OOT' : 'Task assignee removed - process task' });
                }
              }
            }
          }

          /**
           * @ngdoc method
           * @name resetEditMode
           * @param {*} noClosePopup
           * @description
           * Reset right pane edit mode
           */
          function resetEditMode(noClosePopup) {
            $ctrl.editMode = false;
            $ctrl.titleEditable = false;
            $ctrl.activeTab = 'config';
            $ctrl.selectedStepType = _.find($ctrl.taskTypes, { value: $ctrl.task.task_type });
            if (!noClosePopup) {
              closePopup();
            }
          }

          function guestClosePopup() {
            discardChanges();
          }

          /**
           * @ngdoc method
           * @name closePopup
           * @description
           * Close Overall Popup model
           */
          function closePopup() {
            $ctrl.assignedPopoverIsOpen = false;
            $ctrl.isStartedAtOpen = false;
            $ctrl.duePopoverIsOpen = false;
            $ctrl.summaryPopoverIsOpen = false;
            $ctrl.jobIsOpen = false;
          }

          /**
           * @ngdoc method
           * @name onClickOpen
           * @param {String} type
           * @description
           * Open popup model to edit task data
           */
          function onClickOpen(type) {
            switch (type) {
              case 'assign':
                $ctrl.assignedPopoverIsOpen = true;
                $ctrl.isKoTaskAssignee = false;
                break;
              case 'due':
                $ctrl.duePopoverIsOpen = true;
                break;
              case 'summary':
                prepareFroalaConfig();
                $ctrl.summaryPopoverIsOpen = true;
                break;
              case 'started_at':
                $ctrl.isStartedAtOpen = true;
                break;
              case 'milestone':
                $ctrl.milestonePopoverIsOpen = true;
                break;
              case 'job':
                $ctrl.jobIsOpen = true;
                break;
            }
          }

          /**
           * @ngdoc method
           * @name deleteStandaloneTask
           * @param {event} event
           * @return void
           *
           * @description
           * to delete standalone task
           */
          function deleteStandaloneTask(event) {
            event.stopPropagation();
            $ctrl.isOpenPopOver = $ctrl.isTaskFavoritePopoverOpen = false;
            CompactTaskService.deleteStandaloneTask({
              taskId: $ctrl.task.id,
              index: $ctrl.index
            }).then(function () {
              $rootScope.$emit('RIGHT_PANE:CLOSE', {
                event: event
              });
              trackEvent('Task edited', {
                action: 'delete'
              });
            });
          }

          /**
           * @ngdoc method
           * @name onPaneClose
           * @param {*} e
           *
           * @description
           * on right pane method handler
           */
          function onPaneClose(e) {
            if ($ctrl.asModal) {
              $ctrl.close();
            } else {
              e.preventDefault();
              trackEvent('Task - Right Pane > Task - Right Pane - Close', { category: 'Task - Right Pane' });
              if (!$ctrl.isGuest && $ctrl.isShowSaveButton) {
                $ctrl.editForm.$setDirty(true);
              }
              $rootScope.$emit('RIGHT_PANE:CLOSE', {
                event: e
              });
            }
          }

          /**
           * @name trackEvent
           * @private
           * @param {*} eventName
           * @param  {*} properties
           *
           * @description
           * method to handling analytics event
           */
          function trackEvent(eventName, properties) {
            properties.type = $ctrl.task.is_oneoff_task ? 'standalone' : 'process';
            properties.processId = $ctrl.process ? $ctrl.process.id : void 0;
            properties.taskId = $ctrl.task.id;
            $analytics.eventTrack(eventName, properties);
          }

          /**
           * @ngdoc method
           * @name onHeadingClick
           * @param {*} e
           *
           * @description
           * heading title click handler
           */
          function onHeadingClick(e) {
            if ($ctrl.isPublicProcess || $ctrl.asModal) {
              return;
            }
            e.stopImmediatePropagation();
            $state.go($ctrl.isGuest ? 'guest.runs.view' : 'run.view', {
              id: $ctrl.task.run_id,
              activeTask: $ctrl.task.id
            });
          }

          /**
           * @ngdoc method
           * @name onEditableElementClick
           *
           * @description
           * on editable elements click
           */
          function onEditableElementClick() {
            if ($ctrl.isGuest || !TasksService.isTaskEditable({ task: $ctrl.task, owners: $ctrl.task.owners }, $ctrl.orgGroups)) {
              return;
            }
            if ($ctrl.editMode || ($ctrl.isGuest || $ctrl.task.status === 'completed' || $ctrl.isPublicProcess)) {
              return;
            }
            oldTask = angular.copy($ctrl.task);
            $ctrl.editMode = true;
          }

          /**
           * @ngdoc method
           * @name isTaskEditingDisabled
           *
           * @description
           * Check if task is editable
           */
          function isTaskEditingDisabled() {
            return ($ctrl.isGuest || _.get($ctrl.task, 'status') === 'completed' || $ctrl.isPublicProcess || ($ctrl.task && !TasksService.isTaskEditable({ task: $ctrl.task || {}, owners: $ctrl.task.owners }, $ctrl.orgGroups, true)));
          }

          /**
           * @ngdoc method
           * @name getRunStatus
           * @param {*} run
           * @returns runViewStatus
           * @description
           * to get view helper of run's status
           */
          function getRunStatus(run) {
            var runViewStatus = RunsService.getViewHelperRunStatus(run);
            $ctrl.runStatusClass = runViewStatus.statusClass;
            $ctrl.closestDeadline = RunsService.getClosestRunDeadline(metaTasks || []);
            return runViewStatus.runStatus;
          }

          /**
           * @ngdoc method
           * @name onTitleEditable
           * @description
           * click on title edit task in right pane
           */
          function onTitleEditable() {
            if ($ctrl.isGuest || !TasksService.isTaskEditable({ task: $ctrl.task, owners: $ctrl.task.owners }, $ctrl.orgGroups)) {
              return;
            }
            $ctrl.editMode = true;
            $timeout(function () {
              $ctrl.titleEditable = true;
            }, 500);
          }

          /**
           * File deleted handler
           */
          onFileDeleteHandler = $rootScope.$on('TASK:DELETE_FILE', function (e, data) {
            var file = _.get(data, 'file'), field = _.get(data, 'field');
            var files = _.get($ctrl.task, 'taskdata[' + field.id + ']', {});
            if (!files) {
              return;
            }
            var fileIndex = _.findIndex(files, { url: file.url });
            files.splice(fileIndex, 1);
            blockUI.start();
            CompactTaskService.updateTask($ctrl.task, {
              taskdata: $ctrl.task.taskdata
            }).then(function (response) {
              $ctrl.formSubmitted = false;
              angular.extend($ctrl.task, response.data);
              $rootScope.$broadcast('CAPTURE:UPDATE', { updatedTask: $ctrl.task, updatedCapture: field });
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          });

          // watch for threads data
          taskThreadsWatcher = $scope.$watchCollection('$ctrl.task.threads.data', function (value) {
            if (value) {
              $ctrl.isHasIssue = CompactTaskService.isHasIssue($ctrl.task);
            }
          });

          // watch for threads data
          unregisterUpdateRunListener = $rootScope.$on('RUN_DETAILS:UPDATED', function (event, data) {
            _.set($ctrl.task, 'run.data.status', _.get(data, 'status'));
          });

          // watch for threads data edited
          unregisteredIssueReportedEventHandler = $rootScope.$on('ISSUE:REPORTED', function (e, data) {
            if (data.issue && data.issue.task_id === $ctrl.task.id) {
              $ctrl.isHasIssue = CompactTaskService.isHasIssue($ctrl.task);
            }
          });

          // watch for threads data edited
          unregisteredIssueResolvedEventHandler = $rootScope.$on('ISSUE:RESOLVED', function (e, data) {
            _.extend($ctrl.task, { problem: _.get(data, 'issue.problem') });
            if (data.issue && data.issue.task_id === $ctrl.task.id) {
              $ctrl.isHasIssue = CompactTaskService.isHasIssue($ctrl.task);
            }
          });

          /**
           * @ngdoc method
           * @name setCompleteProgress
           *
           * @description
           * set configuration for everyone_must_complete
           */
          function setCompleteProgress() {
            if ($ctrl.task.everyone_must_complete) {
              var owners = _.get($ctrl.task, 'completion_progress.completed_by', {});
              var waitingCompleter = _.get($ctrl.task, 'completion_progress.waiting', {});
              $ctrl.completePercent = (100 * $ctrl.task.completion_progress.completed) / $ctrl.task.completion_progress.total;
              if ($rootScope.identity.guest) {
                $ctrl.iHaveCompleted = !_.includes(waitingCompleter.guests, $rootScope.identity.guest.email);
              } else {
                $ctrl.iHaveCompleted = _.includes(owners.users, $rootScope.identity.id)
                  ? !_.includes(waitingCompleter.users, $rootScope.identity.id)
                  : false;
              }
              $ctrl.completeProgressCaption = $ctrl.task.completion_progress.completed + '/' + $ctrl.task.completion_progress.total;
              $ctrl.approveProgressCaption = $ctrl.task.everyone_must_complete ? $ctrl.task.completion_progress.approved_count + '/' + $ctrl.task.completion_progress.total : angular.noop();
              $ctrl.rejecteProgressCaption = $ctrl.task.everyone_must_complete ? $ctrl.task.completion_progress.rejected_count + '/' + $ctrl.task.completion_progress.total : angular.noop();
              $ctrl.guestCompleters = _.difference($ctrl.isGuest
                ? ($ctrl.iHaveCompleted
                  ? [$rootScope.identity.guest.email]
                  : []
                ) : owners.guests, waitingCompleter.guests);
              var mappedIds = $ctrl.isGuest
                ? _.map(owners.users, function (user) {
                  return user.id;
                }) : owners.users;
              var completerIds = _.difference(mappedIds, waitingCompleter.users);
              $ctrl.userCompleters = _.filter(owners.users, function (user) {
                return _.indexOf(completerIds, user.id || user) !== -1;
              });
            }
          }

          /**
           * @ngdoc method
           * @name getButtonClass
           *
           * @description
           * get complete button class
           */
          function getButtonClass() {
            var buttonClass = 'btn btn-compact btn-compact-with-fa-icon text-uppercase v-c';
            buttonClass += $ctrl.task.everyone_must_complete
              ? (
                $ctrl.task.status !== 'completed'
                  ? (
                    $ctrl.iHaveCompleted
                      ? ' i-have-completed' + (
                        $ctrl.isHover
                          ? ' on-hover btn-dark'
                          : ''
                      )
                      : ''
                  )
                  : ' btn-dark')
              : (
                $ctrl.task.status !== 'completed'
                  ? ' nasty-green'
                  : ' btn-dark'
              );
            return buttonClass;
          }

          /**
           * @ngdoc method
           * @name getButtonText
           *
           * @description
           * get complete button text
           */
          function getButtonText() {
            var buttonText =
              $ctrl.task.task_type === 'email'
                ? ($ctrl.task.status !== 'completed' ? ($ctrl.isUserOnlyAssignee ? 'complete' : 'send') : 'reOpen')
                : ($ctrl.task.everyone_must_complete && $ctrl.isCanCompleteTask
                  ? (
                    $ctrl.task.status !== 'completed'
                      ? (
                        $ctrl.iHaveCompleted
                          ? (
                            $ctrl.isHover
                              ? 'reOpen'
                              : 'completePercent'
                          )
                          : 'completePercent'
                      )
                      : 'reOpen'
                  )
                  : (
                    $ctrl.task.status !== 'completed'
                      ? 'complete'
                      : 'reOpen'
                  )
                );
            return $filter('translate')('compact.task.detail.form.button.' + buttonText, {
              percent: $ctrl.completeProgressCaption
            });
          }

          /**
           * @ngdoc method
           * @name getButtonIconClass
           *
           * @description
           * get complete button icon class
           */
          function getButtonIconClass() {
            return $ctrl.isHasIssue
              ? 'pos-rlt fas fa-exclamation-triangle'
              : $ctrl.task.task_type === 'email'
                ? 'fas fa-envelope'
                : ($ctrl.task.everyone_must_complete
                  ? (
                    $ctrl.task.status !== 'completed'
                      ? (
                        $ctrl.iHaveCompleted
                          ? (
                            $ctrl.isHover
                              ? 'fas fa-check-circle'
                              : ''
                          )
                          : ''
                      )
                      : 'fas fa-check-circle'
                  )
                  : (
                    $ctrl.task.status !== 'completed'
                      ? (
                        $ctrl.isCanCompleteTask
                          ? 'far fa-circle'
                          : ''
                      )
                      : 'fas fa-check-circle'
                  ));
          }

          /**
           * @ngdoc method
           * @name getApprovalButtonIconClass
           *
           * @description
           * get approval button icon class
           * @param {String} buttonType
           */
          function getApprovalButtonIconClass(buttonType) {
            var buttonIcon;
            if (buttonType === 'approve') {
              buttonIcon = 'far fa-circle';
            } else {
              buttonIcon = 'fas fa-times';
            }
            return buttonIcon;
          }

          /**
           * @ngdoc method
           * @name getApprovalButtonText
           *
           * @description
           * get approval button text
           * @param {String} buttonType
           */
          function getApprovalButtonText(buttonType) {
            $ctrl.completerAssignId = _.includes($ctrl.userCompleters, $rootScope.identity.id);
            var buttonText;
            if (buttonType === 'approve') {
              if ($ctrl.completerAssignId || ($ctrl.iHaveCompleted && $ctrl.isGuest)) {
                buttonText = 'reOpen';
              } else {
                buttonText = $ctrl.task.status === 'completed' ? $ctrl.isApproveButtonHover ? 'reOpen' : 'approved' : 'approvePercent';
              }
            } else {
              if ($ctrl.completerAssignId || ($ctrl.iHaveCompleted && $ctrl.isGuest)) {
                buttonText = 'reOpen';
              } else {
                buttonText = $ctrl.task.status === 'completed' ? $ctrl.isRejectButtonHover ? 'reOpen' : 'rejected' : 'rejectPercent';
              }
            }
            return $filter('translate')('compact.task.detail.form.button.' + buttonText, {
              percent: buttonType === 'approve' ? $ctrl.approveProgressCaption : $ctrl.rejecteProgressCaption
            });
          }

          /**
           * @ngdoc method
           * @name getApprovalButtonClass
           *
           * @description
           * get approval button class
           * @param {String} buttonType
           */
          function getApprovalButtonClass(buttonType) {
            var buttonClass = 'btn btn-compact btn-compact-with-fa-icon text-uppercase v-c';
            if (buttonType === 'approve') {
              buttonClass = buttonClass + ($ctrl.task.status === 'completed' ? ' btn-dark ' : ' nasty-green');
            } else {
              buttonClass = buttonClass + ($ctrl.task.status === 'completed' ? ' btn-dark ' : ' red-tomato');
            }
            return buttonClass;
          }

          /**
           * @ngdoc method
           * @name getExpiringButtonText
           *
           * @description
           * get expiring type button text
           */
          function getExpiringButtonText() {
            $ctrl.buttonType = ($ctrl.task.status !== 'completed') ? $ctrl.task.task_type === 'expiring_email' ? 'sendRightNow' : 'acknowledge'
              : ($ctrl.task.status_label === 'acknowledged') ? 'reOpen'
                : $ctrl.task.status_label;
            return $filter('translate')('compact.task.detail.form.button.' + $ctrl.buttonType);
          }

          function onLinkOOTToProcess() {
            $ctrl.isOpenPopOver = $ctrl.isTaskFavoritePopoverOpen = $ctrl.isOpenMobilePopOver = false;
            var isAuthed = AuthPlan.hasAnyAuthority(PLANS.RESTRICATED_PLANS, 'standaloneAdvancedSettings');
            if (isAuthed) {
              var modalInstance = OOTAdvancedSettingsService.openModalOOTAdvancedSetting($ctrl.task);
              modalInstance.then(function (task) {
                CompactTaskService.linkOOTTask(task.id, task.run_id, task.position)
                  .then(function (res) {
                    angular.extend($ctrl.task, res.data);
                    $ctrl.task.run_id ? updateTaskRun() : $ctrl.task = _.omit($ctrl.task, ['run']);
                    $rootScope.$emit('COMPACT_TASK:TASK_LINKED', { task: $ctrl.task });
                  }, function () {
                    $ctrl.task.run_id = null;
                  });
              }, function (task) {
                $ctrl.task = _.omit($ctrl.task, ['run_id', 'position', 'run']);
                angular.extend($ctrl.task, task);
                if ($ctrl.task.run_id) {
                  updateTaskRun();
                } else {
                  $ctrl.task = _.omit($ctrl.task, ['omit']);
                }
              });
            }
          }

          function onUnlinkOOTFromProcess() {
            $ctrl.isOpenPopOver = $ctrl.isTaskFavoritePopoverOpen = $ctrl.isOpenMobilePopOver = false;
            var isAuthed = AuthPlan.hasAnyAuthority(PLANS.RESTRICATED_PLANS, 'standaloneAdvancedSettings');
            if (isAuthed) {
              CompactTaskService.unlinkOOTTask($ctrl.task.id, $ctrl.task.run_id, $ctrl.task.position)
                .then(function (res) {
                  angular.extend($ctrl.task, res.data);
                  $ctrl.task.run_id = null;
                  $ctrl.task = _.omit($ctrl.task, ['run', 'run_status']);
                  $rootScope.$emit('COMPACT_TASK:TASK_LINKED', { task: $ctrl.task });
                  $rootScope.$emit('COMPACT_TASK:UNLINK_FROM_PROCESS', {
                    stageId: $ctrl.task.stage_id,
                    taskId: $ctrl.task.id,
                    index: $ctrl.index
                  });
                });
            }
          }

          function getStageData() {
            if (!$ctrl.process && !$ctrl.isGuest) {
              RunsService.get({
                id: $ctrl.task.run_id,
                with: 'stages'
              }).then(function (res) {
                taskProcess = res.data;
                $ctrl.processStages = _.get(res, 'data.stages.data', []);
              });
            } else {
              $ctrl.processStages = $ctrl.process.stages.data;
            }
          }

          function updateTaskRun() {
            RunsService.get({
              id: $ctrl.task.run_id
            }).then(function (res) {
              angular.extend($ctrl.task, {
                run: {
                  data: res.data
                }
              });
              $ctrl.progessBar = {
                'progessBarClass': RunsService.getBarClass($ctrl.task.run.data),
                'progessBarRunStatus': getRunStatus($ctrl.task.run.data),
                'progessBarPercent': $ctrl.task.run.data.whole_progress.percent,
                'progessBarComplete': $ctrl.task.run.data.whole_progress.complete,
                'progessBarTotal': $ctrl.task.run.data.progress.total
              };
            });
          }

          /**
           * @ngdoc method
           * @name enquireAboutStatus
           * @description
           * click on dots list Enquire about status
           */
          function enquireAboutStatus() {
            var commentMessage = CompactTaskService.setEnquireCommentMessage($ctrl.task);
            $rootScope.$emit('ENQUIRY_STATUS:TASK_ADD_COMMENT', { comment: commentMessage, task: $ctrl.task });
            $ctrl.isOpenPopOver = $ctrl.isTaskFavoritePopoverOpen = $ctrl.isOpenMobilePopOver = false;
          }

          function prepareInsertVariables() {
            if (Helper.isObjectEmpty($ctrl.task.run) && $ctrl.task.is_oneoff_task) {
              formFieldData = $ctrl.task;
              mapFormFields(_.get(formFieldData, 'ko_form_fields', []), _.get(formFieldData, 'form_fields', []));
              return;
            }
            var request =
              !$ctrl.isGuest
                ? ($ctrl.isPublicProcess
                  ? CompactTaskService.getPublicProcessFormFields({ runId: $ctrl.task.run_id })
                  : angular.noop())
                : PublicOrganizationService.getGuestRunFormFields($ctrl.task.run.data.id);
            if (request) {
              request.then(function (res) {
                formFieldData = res.data;
                mapFormFields(_.get(formFieldData, 'ko_form_fields', []), _.get(formFieldData, 'form_fields', []));
              });
            } else {
              var parent = _.get($ctrl.task, 'run.data') || $ctrl.process;
              formFieldData = parent;
              mapFormFields(_.get(parent, 'ko_form_fields.data', []), _.get(parent, 'form_fields.data', []));
            }
          }

          function mapFormFields(koFormFields, formFields) {
            if (koFormFields.length) {
              $ctrl.insertVariablesForm.push({
                name: $filter('translate')('steps.logic.label.pre_run'),
                type: 'Prerun',
                capture: truncateFields(koFormFields)
              });
            }
            if (formFields.length) {
              var mappedFormFields = {};
              for (var i = 0; i < formFields.length; i++) {
                if (!mappedFormFields[formFields[i].step_id]) {
                  mappedFormFields[formFields[i].step_id] = {
                    id: formFields[i].step_id,
                    title: formFields[i].task_title
                  };
                }
                if (Helper.isObjectEmpty(mappedFormFields[formFields[i].step_id].captures)) {
                  mappedFormFields[formFields[i].step_id].captures = [];
                }
                mappedFormFields[formFields[i].step_id].captures.push(formFields[i]);
              }
              var index = 0;
              _.map(mappedFormFields, function (mappedFormField) {
                if (mappedFormField.captures.length > 0) {
                  $ctrl.captures = truncateFields(mappedFormField.captures);
                  $ctrl.insertVariablesForm.push({
                    id: mappedFormField.id,
                    name: $filter('translate')('steps.describes.insertVariable.step', { stepNumber: ++index, stepName: truncateName(mappedFormField.title, 30) }),
                    type: 'Step',
                    capture: $ctrl.captures
                  });
                }
              });
            }
            prepareFroalaConfig();
          }

          function prepareFroalaConfig() {
            angular.extend(toolbarButtons, {
              moreMisc: {
                buttons: [
                  AuthPlan.isRestricted(PLANS.RESTRICATED_PLANS) ? 'insertVariableDropdownStarter' : 'insertVariableDropdown'
                ],
                align: 'right',
                buttonsVisible: 2
              }
            });
            $ctrl.summaryEditorOptions = Helper.getFroalaOptions({
              isGuest: $ctrl.isGuest,
              allowEdit: Helper.checkAccessAuthority(false),
              placeholder: $filter('translate')('steps.describes.describe_placeholder'),
              currentUser: $rootScope.identity,
              heightMin: 250,
              toolbar: toolbarButtons,
              type: 'blueprint',
              entityType: 'process',
              enableFullscreen: true
            }, false, toolbarButtons);
            angular.extend($ctrl.summaryEditorOptions, {
              variableValue: $ctrl.insertVariablesForm,
              isFreePlan: AuthPlan.getCurrentPlanCode() === PLANS.FREE,
              upgradeModal: upgradeModal,
              enter: FroalaEditor.ENTER_BR,
              transitionOnShow: true,
              resizeable: true
            });
          }

          function truncateName(name, length) {
            if (_.size(name) > length) {
              return name.substring(0, length) + '...';
            }
            return name;
          }

          function truncateFields(fields) {
            if (!_.isArray(fields) || _.isEmpty(fields)) {
              return [];
            }
            var copyFields = angular.copy(fields);
            copyFields = _.filter(copyFields, function (cpField) {
              return !cpField.isNew;
            });
            return _.map(copyFields, function (field) {
              return angular.extend(field, {
                label: _.size(field.label) > 25 ? field.label.substring(0, 25) + '...' : field.label,
                type_field: $filter('translate')('steps.describes.insertVariable.' + field.field_type) + " " + (field.alias || '').split('-').pop()
              });
            });
          }

          function getStepCaptures() {
            var taskCaptures = (_.get($ctrl.task, 'form_fields.data', []) || []);
            if (taskCaptures.length) {
              _.forEach(taskCaptures, function (capture) {
                capture.value = $ctrl.task.taskdata[capture.id];
              });
            }
            return taskCaptures;
          }

          function upgradeModal() {
            AuthPlan.hasAnyAuthority(PLANS.RESTRICATED_PLANS, 'insertVariable');
          }

          function toggleIsAssigneeLimited() {
            $ctrl.task.max_assignable = $ctrl.isAssigneeLimited ? 1 : 0;
          }

          function onTagsDataChanged() {
            getLatestTaskData();
          }

          function publicCanDoTaskAction() {
            if ($ctrl.isPublicProcess) {
              return (!$ctrl.isPublicProcess || $ctrl.isGuestAssigned)
                && (($ctrl.isPublicProcess || $ctrl.isGuest) && !$ctrl.task.task_read_only_for_guest) ||
                ((!$ctrl.isPublicProcess && !$ctrl.isGuest) && $ctrl.isCanCompleteTask);
            }
            return true;
          }

          /**
           * @ngdoc method
           * @name updateDeadline
           * @description
           * update deadline value
           * @param {*} value
           * @param {*} type
           */
          function updateDeadline(value, type) {
            $ctrl.isShowSaveButton = true;
            var deadline = $ctrl.task.deadline;
            $ctrl.task.deadline = moment(deadline).add(value, type).format();
          }

          /**
           * @ngdoc method
           * @name updateStartAt
           * @description
           * update start date value
           * @param {*} value
           * @param {*} type
           */
          function updateStartAt(value, type) {
            $ctrl.isShowSaveButton = true;
            var started_at = $ctrl.task.started_at;
            $ctrl.task.started_at = moment(started_at).add(value, type).format();
          }

          /**
           * @function
           * @name onFolderRemoved
           * @param {*} $folder
           * @description Callback function onFolderRemoved
           */
          function onFolderRemoved($folder) {
            blockUI.start();
            FolderService.deleteObjectFromFolder({ id: $folder.id })
              .then(function () {
                var index = _.findIndex($ctrl.task.folders.data, { 'folder_id': $folder.folder_id });
                if (index > -1) {
                  $ctrl.task.folders.data.splice(index, 1);
                }
                growl.success($filter('translate')('runs.messages.foldersForRunsAndTasks.removedFromFolder', { objectName: $ctrl.task.title, folderName: $folder.folder_name }),
                  { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                oldTask = angular.copy($ctrl.task);
                setEditFormPristine();
                blockUI.stop();
              }, function () {
                $ctrl.task.folders.data = oldTask.folders.data;
                setEditFormPristine();
                blockUI.stop();
              });
          }

          function setEditFormPristine() {
            if ($ctrl.editForm) {
              $ctrl.editForm.$setPristine();
            }
          }

          function addTaskToFolders(event) {
            event.stopImmediatePropagation();
            $ctrl.isOpenPopOver = $ctrl.isTaskFavoritePopoverOpen = false;
            var openTaskFoldersModal = FolderService.openObjectFoldersModal($ctrl.foldersList, $ctrl.task.id, 'Task', $ctrl.task.folders.data, $ctrl.task.title);
            openTaskFoldersModal.result.then(function (data) {
              $ctrl.task.folders.data = data;
              oldTask = angular.copy($ctrl.task);
            }, function (data) {
              $ctrl.task.folders.data = data;
              oldTask = angular.copy($ctrl.task);
            });
          }

          function copyTaskLink(e) {
            var owners = _.get($ctrl.task, 'owners', {});
            if (_.get(owners, 'guests', []).length) {
              $uibModal.open({
                component: 'copyGuestUrl',
                windowClass: 'copy-guest-url-modal',
                backdrop: 'static',
                resolve: {
                  owners: function () {
                    return owners;
                  },
                  showMemberLink: function () {
                    return !!_.get(owners, 'users', []).length;
                  }
                }
              });
            } else {
              var text = $ctrl.task.is_oneoff_task ?
                ENV_CONFIG.TYFY_MAIN_URL + $state.href('home', { org_id: $ctrl.organizationId, activeTask: $ctrl.task.id }) :
                ENV_CONFIG.TYFY_MAIN_URL + $state.href('run.view', { org_id: $ctrl.organizationId, id: $ctrl.task.run_id, activeTask: $ctrl.task.id });
              Helper.copyToClipboard(text, 'taskLinkCopied', e);
            }
          }

          function activateAccordion(accordionId) {
            if (accordionId && $ctrl.taskDetailAccordion) {
              $ctrl.taskDetailAccordion.control.expand(accordionId);
            }
          }

          function milestoneSelected(milestone) {
            if ($ctrl.task.stage_id == _.get(milestone, 'id', null)) {
              return;
            }
            var process = $ctrl.process || taskProcess;
            if (!milestone) {
              $ctrl.task.stage_id = null;
              MilestoneService.unLinkTaskToMilestone(process.id, $ctrl.task.id)
                .then(function (res) {
                  if ($ctrl.process) {
                    $rootScope.$emit('STAGE:TASK_UNLINKED', { task: $ctrl.task, stage: milestone });
                  }
                });
            } else {
              if ($ctrl.task.stage_id) {
                if ($ctrl.process) {
                  $rootScope.$emit('STAGE:TASK_UNLINKED', { task: $ctrl.task, stage: _.find($ctrl.processStages, { id: $ctrl.task.stage_id }) });
                }
              }
              $ctrl.task.stage_id = milestone.id;
              MilestoneService.linkTaskToMilestone(process.id, $ctrl.task.id, milestone.id)
                .then(function (res) {
                  if ($ctrl.process) {
                    $rootScope.$emit('STAGE:TASK_LINKED', { task: $ctrl.task, stage: milestone });
                  }
                });
            }
            $ctrl.milestonePopoverIsOpen = false;
          }

          function createNewMilestone() {
            var modal = $uibModal.open({
              component: 'createMilestone',
              windowClass: 'create-milestone-modal',
              resolve: {
                process: function () {
                  return $ctrl.process || taskProcess;
                }
              }
            });

            modal.result.then(function (res) {
              var process = $ctrl.process || taskProcess;
              MilestoneService.createTemplateMilestone(process.id, res.name, 'Run', $ctrl.processStages.length + 1)
                .then(function (res) {
                  if ($ctrl.process) {
                    if ($ctrl.task.stage_id) {
                      $rootScope.$emit('STAGE:TASK_UNLINKED', { task: $ctrl.task, stage: _.find($ctrl.processStages, { id: $ctrl.task.stage_id }) });
                    }
                  }
                  $ctrl.task.stage_id = res.data.id;
                  MilestoneService.linkTaskToMilestone(process.id, $ctrl.task.id, res.data.id)
                    .then(function () {
                      $ctrl.processStages.push(res.data);
                      if ($ctrl.process) {
                        $rootScope.$emit('STAGE:TASK_LINKED', { task: $ctrl.task, stage: res.data, newStage: true });
                      }
                    });
                });
            });
          }

          watchSummaryNeedToBeUpdated = $scope.$watch('$ctrl.task.summaryNeedToBeUpdated', function (value) {
            if (value) {
              getLatestTaskData();
            }
          });

          taskCompleteEventHandler = $rootScope.$on('COMPACT_TASK:COMPLETED', function (e, data) {
            if (data.task.id === $ctrl.task.id) {
              angular.extend($ctrl.task, data.task);
              setCompleteProgress();
              getLatestTaskData();
            }
          });

          var guestAssignEditModeEventHandler = $rootScope.$on('RIGHT_PANE:EDIT_MODE', function () {
            onEditableElementClick();
          });

          var rightPaneEditModeOpenEventWatchHandler = $scope.$watch('$ctrl.editMode', function (value) {
            if (value) {
              prepareFroalaConfig();
            }
          });

          editTaskEventWatchHandler = $scope.$watchCollection('$ctrl.task', function (value) {
            if (value && oldTask && (value.original_title !== oldTask.original_title)) {
              $ctrl.isShowSaveButton = true;
            }
          });

          editTaskFormFieldsWatchHandler = $scope.$watch('$ctrl.task.form_fields.data', function (value) {
            var formFieldsToUpdate = _.filter(value, function (t) { return t.isFormUpdate && !t.isFormCreate; }),
              formFieldsToCreate = _.filter(value, function (t) { return t.isFormCreate; });
            if (formFieldsToUpdate.length > 0 || formFieldsToCreate.length > 0) {
              $ctrl.isShowSaveButton = true;
            }
          }, true);

          //Function to check if user is the only assignee in email task
          function checkIfUserIsOnlyAssignee() {
            if ($ctrl.isGuest || $ctrl.isPublic || $ctrl.isPublicProcess) {
              return false;
            }

            if ((_.get($ctrl.task, 'owners.users', []).length > 1 || _.get($ctrl.task, 'owners.guests', []).length > 1) || (_.get($ctrl.task, 'owners.users', []).length === 1 && _.get($ctrl.task, 'owners.guests', []).length === 1)) { // Not checking the groups here as multiple groups can have just a single and the same assignee too.
              return false;
            }

            if ((_.get($ctrl.task, 'owners.users', []).length === 1 && _.get($ctrl.task, 'owners.guests', []).length === 0 && _.get($ctrl.task, 'owners.groups', []).length === 0 && _.get($ctrl.task, 'owners.users[0]') === $rootScope.identity.id) || (_.get($ctrl.task, 'owners.users', []).length === 0 && _.get($ctrl.task, 'owners.guests', []).length === 1 && _.get($ctrl.task, 'owners.groups', []).length === 0 && _.get($ctrl.task, 'owners.guests[0]') === _.get($rootScope, 'identity.guest.email'))) {
              return true;
            }

            if (_.get($ctrl.task, 'owners.groups', []).length > 0) {
              var allAssignedArr = _.concat($ctrl.task.owners.users, $ctrl.task.owners.guests) || [];

              if (allAssignedArr.length > 1) {
                return false;
              }

              var groupsWithUserIds = getTaskGroups(), isOnlyAssignee = false;

              for (var i = 0; i < groupsWithUserIds.length; i++) {
                if (groupsWithUserIds[i].members.length > 1 || groupsWithUserIds[i].guests.length > 1) {
                  isOnlyAssignee = false;
                  break;
                }

                if (groupsWithUserIds[i].members.length) {
                  allAssignedArr = _.uniq(_.concat(allAssignedArr, groupsWithUserIds[i].members[0]));
                }

                if (groupsWithUserIds[i].guests.length) {
                  allAssignedArr = _.uniq(_.concat(allAssignedArr, groupsWithUserIds[i].guests[0]));
                }

                if (allAssignedArr.length > 1) {
                  isOnlyAssignee = false;
                  break;
                }
              }

              if (allAssignedArr.length === 1) {
                isOnlyAssignee = true;
              }

              return isOnlyAssignee;
            }

            return false;
          }

          //Get Task Groups
          function getTaskGroups() {
            var taskGroups = [];
            if (_.get($ctrl.orgGroups, 'length', 0)) {
              var groupsAssigned = _.get($ctrl.task, 'owners.groups') || [];
              _.forEach(groupsAssigned, function (groupItem) {
                var groupBaseObj = _.find($ctrl.orgGroups, { id: groupItem });
                if (groupBaseObj) {
                  taskGroups.push(groupBaseObj);
                }
              });
            }
            return taskGroups;
          }

          /**
           * Duplicate a task
           * @type {*|(function())}
           */
          function duplicateTask() {
            var payload = {
              task_id: $ctrl.task.id,
              org: $ctrl.organizationId
            };
            if ($ctrl.task.run_id) {
              payload.run_id = $ctrl.task.run_id;
            }
            blockUI.start();
            CompactTaskService.duplicateTask(payload).then(function (response) {
              if (response.data.run_id) {
                updateTaskRun();
                $rootScope.$emit('COMPACT_TASK:CREATED', { task: response.data });
              } else {
                $rootScope.$emit('STANDALONE_TASK:CREATED', { task: response.data, taskOpen: true });
              }
              $rootScope.$emit('RIGHT_PANE:OPEN', {
                item: {
                  task: response.data,
                  index: response.data.position
                }
              });
              blockUI.stop();
            });
          }

          function koTaskAssigneeCheck() {
            var tempEl = angular.element('<div>');
            tempEl.html($ctrl.task.summary);
            var koAssigneeElements = tempEl[0].getElementsByTagName('ko-field-assignee');
            if (koAssigneeElements.length) {
              $ctrl.isKoTaskAssignee = true;
            }
          }

          function togglePopoverBackdrop(isShow) {
            if (isShow) {
              rightSectionContentAreaEl.append(popoverBackdropEl);
            } else {
              var el = angular.element(document.querySelector('#tde_popover_backdrop'));
              el.remove();
            }
          }

          function processPopoverToggleChanges(value) {
            if (value === false) {
              togglePopoverBackdrop(false);
            } else if (value === true) {
              togglePopoverBackdrop(true);
            }
          }

          function getPubliclyHiddenAssignees(value) {
            value = value || [];
            var publiclyAssignees = false;
            for (var i = 0; i < value.length; i++) {
              if (value[i] === 'assignees') {
                publiclyAssignees = true;
                break;
              }
            }
            $ctrl.publiclyAssignees = !!(publiclyAssignees && $ctrl.isPublicProcess);
          }

          /**
           * @ngdoc method
           * @name isCurrentMemberInGroupCheck
           * @param {*} groups
           * @description
           * check for member in groups
           */
          function isCurrentMemberInGroupCheck(groups) {
            var isMemberPresent = false, groupsArr = groups || [];
            for (var i = 0; i < groupsArr.length; i++) {
              var groupObj = _.find($ctrl.orgGroups, { id: groupsArr[i] });
              if (groupObj) {
                for (var j = 0; j < groupObj.members.length; j++) {
                  if (groupObj.members[j] === $rootScope.identity.id && ($ctrl.task.owners.users.length >= 1 || groupObj.members.length > 1 || groupsArr.length > 1)) {
                    isMemberPresent = true;
                    break;
                  }
                }
              }
              if (isMemberPresent) {
                break;
              }
            }
            return isMemberPresent;
          }

          /**
           * @ngdoc method
           * @name getProperties
           * @description
           * get task properies
           */
          function getProperties() {
            $ctrl.allAssigneesMustComplete = $ctrl.task.everyone_must_complete ? 'yes' : 'No';
            $ctrl.canCompleteOnlyAssignees = !$ctrl.task.is_oneoff_task && _.get($ctrl.task, 'step.data.can_complete_only_assignees') ? 'yes' : 'No';
            $ctrl.mandatoryLaunch = !$ctrl.task.is_oneoff_task && _.get($ctrl.task, 'step.data.skip_start_process') ? 'yes' : 'No';
            $ctrl.outboundWebhook = !$ctrl.task.is_oneoff_task && $ctrl.task.webhook ? $ctrl.task.webhook : 'No';
            getProcessById();
          }

          /**
           * @ngdoc method
           * @name getProcessById
           * @description
           * get process by id
           */
          function getProcessById() {
            if (_.get($ctrl.task, 'step.data.bp_to_launch')) {
              ProcessService.get({
                id: $ctrl.task.step.data.bp_to_launch.id,
                with: 'steps,steps.captures,steps.threads,tags,folder,steps.tags,steps.roles,assets',
                skipNotFound: true
              }).then(function (response) {
                $ctrl.launchAnotherProcess = _.get(response, 'data');
                $ctrl.processStarter = _.find($ctrl.orgAllUsers, {
                  id: $ctrl.launchAnotherProcess.created_by
                });
              });
            } else {
              $ctrl.launchAnotherProcess = 'No';
            }
          }

          //Window resize handler
          function onWindowResize() {
            $ctrl.assigneeIconType = $window.innerWidth < 576 ? 'icon-only' : 'inline-and-cut';
            $ctrl.assigneeIconSize = $window.innerWidth < 576 ? 'medium' : 'small';
          }

          function onSubHeadingClick(e) {
            e.stopPropagation();
            onTitleEditable();
          }

          function getStageTitle() {
            if (!$ctrl.processStages) {
              return '';
            }
            var stage = $ctrl.processStages.find(function (stage) {
              return stage.id.toString() == $ctrl.task.stage_id.toString();
            });
            return _.get(stage, 'title', '');
          }

          //Handle link to template
          function onLinkOOTToTemplate() {
            $ctrl.isOpenPopOver = $ctrl.isOpenMobilePopOver = false;
            var isAuthed = AuthPlan.hasAnyAuthority(PLANS.RESTRICATED_PLANS, 'standaloneAdvancedSettings');
            if (isAuthed) {
              var modalInstance = OOTAdvancedSettingsService.openModalOOTBlueprintAdvancedSetting($ctrl.task);
              modalInstance.then(function (task) {
                TasksService.createStandaloneTaskLinkByBlueprint({
                  id: _.get(task, 'id'),
                  checklist_id: task.checklist_id,
                  step_id: task.linked_step_id ? task.linked_step_id : void 0
                }).then(function (res) {
                  angular.extend($ctrl.task, res.data);
                  updateTaskTemplate();
                });
              });
            }
          }

          //Handle unlink from template
          function onUnlinkOOTFromTemplate() {
            $ctrl.isOpenPopOver = $ctrl.isOpenMobilePopOver = false;
            var isAuthed = AuthPlan.hasAnyAuthority(PLANS.RESTRICATED_PLANS, 'standaloneAdvancedSettings');
            if (isAuthed) {
              TasksService.unlinkStandaloneTaskFromBlueprint($ctrl.task.id)
                .then(function (res) {
                  angular.extend($ctrl.task, res.data);
                  $ctrl.task.checklist_id = null;
                  $ctrl.task = _.omit($ctrl.task, ['template', 'linked_step_id', 'linkedStep']);
                  $rootScope.$emit('COMPACT_TASK:TASK_LINKED', {
                    task: $ctrl.task
                  });
                });
            }
          }

          //Handle task template update
          function updateTaskTemplate() {
            ProcessService.get({
              id: _.get($ctrl.task, 'checklist_id'),
              with: 'steps',
              skipNotFound: true
            }).then(function (res) {
              angular.extend($ctrl.task, {
                template: {
                  data: res.data
                }
              });
              if ($ctrl.task.linked_step_id) {
                var steps = _.get($ctrl.task.template.data, 'steps.data'), linkedStep = _.find(steps, { id: $ctrl.task.linked_step_id });
                if (linkedStep) {
                  angular.extend($ctrl.task, { linkedStep: linkedStep });
                }
              }
              $rootScope.$emit('COMPACT_TASK:TASK_LINKED', {
                task: $ctrl.task
              });
            });
          }

          function updateJobString(jobRole) {
            $ctrl.jobRoleInString = !$ctrl.jobRoleInString ? jobRole.title : $ctrl.jobRoleInString + ', ' + jobRole.title;
          }

          /**
           * @function
           * @name filterJobs
           * @param {*} q
           * @description search job
           * @param Job
           */
          function filterJobs(q) {
            return _.filter($ctrl.roles, function (value) {
              return value.title.toLowerCase().includes(q.toLowerCase());
            });
          }

          /**
           * @function
           * @name onJobRemoved
           * @description Removed job
           * @param $job
           */
          function onJobRemoved($job) {
            var param = {
              org: $ctrl.organizationId,
              id: $job.role_id
            };
            TasksService.removedJobTasks(param).then(function () {
              $ctrl.jobRoleInString = "";
              for (var i = 0; i < $ctrl.selectJobRole.length; i++) {
                var jobRole = _.find($ctrl.roles, { id: $ctrl.selectJobRole[i].id });
                if (jobRole) {
                  updateJobString(jobRole);
                }
              }
              onTaskSave({}, false);
            }, function () { });
          }

          function prepareEditorData(content) {
            return FroalaService.getViewContentFromSummary(content, _.concat($ctrl.orgGroups, $ctrl.orgAllUsers), null);
          }

          /**
           * @function
           * @name onJobAdded
           * @description Add job
           * @param $job
           */
          function onJobAdded($job) {
            var data = { org_role_id: $job.id, subject_type: "Step", subject_id: _.get($ctrl.task, 'step.data.id', []) };
            TasksService.addedJobTasks({ org_id: $ctrl.organizationId }, data).then(function (res) {
              angular.extend($job, {
                id: _.get(res, 'data.org_role_id'),
                title: $job.title,
                role_id: _.get(res, 'data.id')
              });
              updateJobString($job);
              onTaskSave({}, false);
            }, function () { });
          }

          if (!$ctrl.process) {
            koFieldClickHandler = $rootScope.$on('KO_FIELD_ASSIGNEE:DESCRIPTION_CLICK', function (e, data) {
              $state.go($ctrl.isGuest ? 'guest.runs.view' : 'run.view', {
                org_id: $ctrl.organizationId,
                id: _.get($ctrl.task, 'run_id'),
                activeTask: 'ko-task',
                focusField: data.alias
              });
            });
          }

          if (!$ctrl.isGuest) {
            activateAccordionChangeHandler = $rootScope.$on('COMPACT_TASK:ACTIVATE_ACCORDION', function (e, data) {
              if (data && data.accordionId) {
                activateAccordion(data.accordionId);
              }
            });

            onAccordionReadyHandler = $scope.$on('processFormAccordion:onReady', function (data) {
              if (data && data.targetScope) {
                $ctrl.taskDetailAccordion = data.targetScope;
              }
            });
          }

          taskIndexUpdatedWatcherHandler = $rootScope.$on('COMPACT_TASK:INDEX_UPDATED', function (e, data) {
            if (!$ctrl.task) {
              return;
            }
            if (data.task.id === $ctrl.task.id) {
              $ctrl.index = data.index;
              DOMService.centerObjectToView('.compact-item-selected', {
                behavior: "smooth",
                block: "center"
              });
            }
          });

          contentLanguageChangedHandler = $rootScope.$on('CONTENT_LANGUAGE_CHANGED', function () {
            $ctrl.descriptionTranslateTo = TranslationService.getMyContentLanguage();
          });

          // Assignee popover watcher
          unRegisterAssigneePopupWatcher = $scope.$watch('$ctrl.assignedPopoverIsOpen', function (value) {
            processPopoverToggleChanges(value);
          }, true);

          // Start date popover watcher
          unRegisterStartDatePopupWatcher = $scope.$watch('$ctrl.isStartedAtOpen', function (value) {
            processPopoverToggleChanges(value);
          }, true);

          // Due date popover watcher
          unRegisterDueDatePopupWatcher = $scope.$watch('$ctrl.duePopoverIsOpen', function (value) {
            processPopoverToggleChanges(value);
          }, true);

          // Summary date popover watcher
          unRegisterSummaryPopupWatcher = $scope.$watch('$ctrl.summaryPopoverIsOpen', function (value) {
            processPopoverToggleChanges(value);
          }, true);

          // Milestone date popover watcher
          unRegisterMilestonePopupWatcher = $scope.$watch('$ctrl.milestonePopoverIsOpen', function (value) {
            processPopoverToggleChanges(value);
          }, true);

          // Job popover watcher
          unRegisterJobPopupWatcher = $scope.$watch('$ctrl.jobIsOpen', function (value) {
            processPopoverToggleChanges(value);
          }, true);
        }
    });
})();
